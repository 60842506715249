import { useState, useEffect, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import DoughnutGraph from 'components/Home/DoughnutGraph';
import Toggle from 'components/Toggle';
import * as Constants from "./constants";
import { getAttendanceAndAverageScoreStats } from 'services';
import { getAverageScore } from 'utils';
import { Typography } from '@mui/material';
import VerticalProgressBar from 'components/BatchAnalytics/VerticalProgressBar';
import Tabs from 'components/Tabs';
import { useTheme } from '@mui/styles';

const useStyles = makeStyles(theme=>({
    allContainer: {
        display: 'flex', justifyContent: 'space-between', alignItems: 'center',
        alignSelf: "stretch", height: '50px',
    },
    shadowContainer: {
        display: 'flex', flexDirection: 'column', alignItems: 'center',
        border: "1px solid lightgrey", minHeight: '360px',
        borderRadius: "8px", padding: '8px 12px', margin: 0 , gap:theme.spacing(3)
    },
    avgBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '8px 12px',
    border: '1px solid',
    borderRadius: theme.spacing(1),
    borderColor: theme.palette.neutral['clr-200'],
    backgroundColor: theme.palette.neutral['clr-50']
  }
}));

const sx = {
  rangeBox: {
    display: "flex",
    mt:'10px', 
    justifyContent:'center',
    flexDirection:'column',
    gap:'10px'
  },
}

const AverageScoreLegends = [
  { range: "0-30", color: "#E2483C" },
  { range: "31-60", color: "#F5B847" },
  { range: "61-100", color: "#009970" },
];

export default function RecentPerformance({ batch }) {
    const classes = useStyles();
    const theme = useTheme();

    const [active, setActive] = useState(true);
    const [activeTab , setActiveTab] = useState('averageScore')

    const [attandanceStats, setAttandanceStats] = useState({
        "0-30": 0, "31-60": 0, "61-100": 0
    });
    const [averageScoreStats, setAverageScoreStats] = useState({
        "0-30": 0, "31-60": 0, "61-100": 0
    });
    const [averageScore, setAverageScore] = useState(0);
    const [averageAttandance, setAverageAttandance] = useState(0);
    const [increment, setIncrement] = useState({
        'true': 0,
        'false': 0,
    });

    const totalStudents = useMemo(()=>{
      let total = 0;
      AverageScoreLegends.map(data=>
        total += averageScoreStats[data.range]
      )
  
      return total;
    },[averageScoreStats, AverageScoreLegends])

    useEffect(() => {
        if (batch && batch !== 'all') {
            getAttendanceAndAverageScoreStats(batch, true)
                .then(({ attendanceStats, averageScoreStats }) => {
                    const previousAttendance = attendanceStats.averageAttendance;
                    const previousAverage = averageScoreStats.averageScore;

                    getAttendanceAndAverageScoreStats(batch)
                        .then(({ attendanceStats, averageScoreStats }) => {
                            const currAttendance = attendanceStats.averageAttendance;
                            const currAverage = getAverageScore(
                                averageScoreStats.score.communication,
                                averageScoreStats.score.content
                            );

                            setAttandanceStats(attendanceStats);
                            setAverageScoreStats(averageScoreStats);
                            setIncrement({
                                'true': currAverage - previousAverage,
                                'false': currAttendance - previousAttendance,
                            });
                            setAverageScore(currAverage);
                            setAverageAttandance(currAttendance);
                        })
                        .catch(console.errror)

                })
                .catch(console.errror)
        }
    }, [batch]);

    const handleToggleChange = (option) => {
      setActive(!active)
      setActiveTab(option)
    };

    return (
        <Box className={classes.shadowContainer}>
            <Tabs
                options={Constants.toggleOptions}
                value={activeTab}
                onChange={handleToggleChange}
                style={{width : '161px' , padding : theme.spacing(1.5,1.5)}}
            />
            {(active) ?
              <Box display='flex' justifyContent='center' mt={4}>
                  <Box>
                    <VerticalProgressBar
                      averageScoreStats={averageScoreStats || {}}
                      averageScoreLegends={AverageScoreLegends || []}
                      xAsix={'Score Range'}
                      yAsix={'No. of Students'}
                      totalStudents={totalStudents}
                    />
                    <Box display='flex' justifyContent='center' mt='10px'>
                      <Box className={classes.avgBox}>
                        <Typography variant="h5-bold">
                          {averageScore}
                        </Typography>
                        <Typography variant="h6-medium">
                          Batch Average
                        </Typography>
                        <Typography variant='body01-semiBold' color='neutral.clr-600'>
                          Score consisting of {totalStudents} students
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                :
                <Box display='flex' justifyContent='center' flexDirection='column'>
                  <DoughnutGraph
                    rootStyle={{ padding: 0 }}
                    color="#0263FF"
                    data={[
                      attandanceStats["0-30"] || 0,
                      attandanceStats["31-60"] || 0,
                      attandanceStats["61-100"] || 0,
                    ]}
                    bgColors={Constants.AverageScoresBgColors}
                    borderColors={Constants.AverageScoresBorderColors}
                    labels={["No. of Students", "No. of Students", "No. of Students"]}
                    title="Attendance"
                    description="Avg attempt rate of all students in batch"
                    score={averageAttandance || 0}
                    isPercentage
                  />
                  <Box sx={sx.rangeBox}>
                    <Typography variant="body01-bold" textAlign='center'>
                      No. of students in the score range
                    </Typography>
                    {AverageScoreLegends.map((x,index) => (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent='space-between'
                      >
                          <Box display="flex" alignItems="center" gap='10px'>
                            <Box bgcolor={x.color} p={0.5} borderRadius={8} />
                            <Typography variant='body02-bold'>
                              {x.range}
                            </Typography>
                          </Box>
                        <Typography variant="body02-bold" color='neutral.clr-700'>
                          {attandanceStats[x.range]}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
            }
        </Box>
    );
}
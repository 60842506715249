import React from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import MuiTableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { MenuItem, Select } from '@mui/material';
import { formatDate, getMonths } from 'utils';
import dayjs from 'dayjs';
import { AccessControl } from 'editors/Batch/constants';

const sx = {
  blurCredits : {
    filter: 'blur(2px)', webkitFilter: 'blur(2px)'
  }
}

function TableRow({
  index,
  row,
  onCellEdit = () => { },
  handleMonthChange= () => { },
  Actions = () => null,
  inviteAction,
  reduce,
  isPayPerUser,
  showUserCredits,
  errorRows
}) {
  const [editable, setEditable] = React.useState([false, false])
  const [value, setValue] = React.useState("");

  const editCell = (i, e) => {
    const _editable = [...editable];

    if (editable[i]) {
      onCellEdit({ index, field: e.target.name, value: e.target.value });
    }

    _editable[i] = !_editable[i];

    setValue(i === 0 ? row.name : i===1 ? row.email : row.accessPeriod);

    setEditable(_editable)
  };

  return (
    <MuiTableRow
      sx={{ '&:last-child td, &:last-child th': {}, '& td':{ padding: '0px 5px', fontSize:"12px"} }}
    >
      <TableCell component="td" scope="row" width={26} align="center">
        <Typography display='flex' alignItems='center' gap='3px' justifyContent='center' variant='body02-medium'>
        {
          !showUserCredits 
          ? errorRows.includes(index) 
            ? <span style={{ marginTop: '2px'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M7.33203 9.99967H8.66536V11.333H7.33203V9.99967ZM7.33203 4.66634H8.66536V8.66634H7.33203V4.66634ZM7.9987 1.33301C4.31203 1.33301 1.33203 4.33301 1.33203 7.99967C1.33203 9.76778 2.03441 11.4635 3.28465 12.7137C3.90371 13.3328 4.63864 13.8238 5.44747 14.1589C6.25631 14.4939 7.12322 14.6663 7.9987 14.6663C9.76681 14.6663 11.4625 13.964 12.7127 12.7137C13.963 11.4635 14.6654 9.76778 14.6654 7.99967C14.6654 7.1242 14.4929 6.25729 14.1579 5.44845C13.8229 4.63961 13.3318 3.90469 12.7127 3.28563C12.0937 2.66657 11.3588 2.17551 10.5499 1.84048C9.74108 1.50545 8.87418 1.33301 7.9987 1.33301ZM7.9987 13.333C6.58421 13.333 5.22766 12.7711 4.22746 11.7709C3.22727 10.7707 2.66536 9.41416 2.66536 7.99967C2.66536 6.58519 3.22727 5.22863 4.22746 4.22844C5.22766 3.22824 6.58421 2.66634 7.9987 2.66634C9.41319 2.66634 10.7697 3.22824 11.7699 4.22844C12.7701 5.22863 13.332 6.58519 13.332 7.99967C13.332 9.41416 12.7701 10.7707 11.7699 11.7709C10.7697 12.7711 9.41319 13.333 7.9987 13.333Z" fill="#FF0031"/>
              </svg>
              </span>
            : null
          : 
          row.existingUser ? 
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M13.1987 3.77954C14.032 4.79288 14.5454 6.02621 14.6654 7.33288H13.292C13.1587 6.37954 12.772 5.47954 12.172 4.73288L13.1987 3.77954ZM8.66536 1.36621C9.97203 1.49288 11.2054 1.99954 12.2187 2.83954L11.2654 3.79288C10.512 3.19954 9.61203 2.82621 8.66536 2.69954V1.36621ZM7.33203 1.37288C6.02536 1.50621 4.79203 2.01954 3.7787 2.84621L4.73203 3.79288C5.48536 3.20621 6.38536 2.82621 7.33203 2.70621V1.37288ZM2.8387 3.77954L3.75203 4.70621V4.73288C3.16536 5.48621 2.78536 6.38621 2.66536 7.33288H1.33203C1.47203 6.02621 1.9987 4.78621 2.8387 3.77954ZM1.33203 9.33288V12.6662L2.3987 11.5995C3.58536 13.4462 5.64536 14.6662 7.9987 14.6662C11.212 14.6662 13.912 12.3662 14.532 9.33288H13.1654C12.572 11.6329 10.4787 13.3329 7.9987 13.3329C6.03203 13.3329 4.2587 12.2595 3.33203 10.6662L4.66536 9.33288H1.33203ZM7.9987 11.3329L9.0387 9.05288L11.332 7.99954L9.0387 6.95954L7.9987 4.66621L6.95203 6.95954L4.66536 7.99954L6.95203 9.05288L7.9987 11.3329Z" fill="#02569D"/>
          </svg> 
          : null
        }
        {index + 1}
        </Typography>
      </TableCell>
      <TableCell
        component="td"
        scope="row"
        onClick={() => editCell(0)}
        padding={'none'} align='center'
      >
        {editable[0] ? (
          <input
            autoFocus
            style={{ width: "100%", padding: "4px 12px", height: 40 }}
            placeholder="Name of student"
            type="text"
            value={value}
            name={"name"}
            onChange={(e) => setValue(e.target.value)}
            onBlur={(e) => editCell(0, e)}
          />
        ) : (
          !!row.name ?
            row.name : (
              <Typography variant="caption" color="GrayText">
                Click to add...
              </Typography>
            )
        )}
      </TableCell>
      <TableCell
        padding={'none'}
        component="td"
        scope="row" align='center'
        onClick={() => editCell(1)}
      >
        {editable[1] ? (
          <input
            autoFocus
            style={{ width: "100%", padding: "4px 12px", height: 40 }}
            type="email"
            value={value}
            name={"email"}
            onChange={(e) => setValue(e.target.value)}
            onBlur={(e) => editCell(1, e)}
            placeholder="Email of student"
          />
        ) : (
          !!row.email ?
            row.email : (
              <Typography variant="caption" color="GrayText">
                Click to add...
              </Typography>
            )
        )}
      </TableCell>
      <TableCell
        padding={'none'}
        component="td"
        scope="row" align='center'
      >
        {
          inviteAction !== AccessControl.GRANT ?
            `${getMonths(dayjs(), row.previousExpiry || row.accessExpiresOn)} Month`
          :
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            sx={{ height: '28px', width: '80px', fontSize: '11px', backgroundColor: '#F4F5F5' }}
            size="small"
            name={"accessPeriod"}
            value={ getMonths(row.onboardedAt, row.previousExpiry || row.accessExpiresOn) }
            disabled={inviteAction !== AccessControl.GRANT}
            onChange={e => 
              handleMonthChange(index,e.target.name,e.target.value)
            }
          >
            {Array.from({ length: 12 }, (_, index) => index+1).map((v, i) => (
                <MenuItem key={i} value={v} style={{ fontSize: '10px'}}>
                    {v} Month
                </MenuItem>
            ))}
          </Select>
        }
      </TableCell>

      {/* upgrade access */}
      {
        inviteAction !== AccessControl.GRANT ?
        <TableCell
          padding={'none'}
          component="td"
          scope="row" align='center'
        >
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            sx={{ height: '28px', width: '80px', fontSize: '11px', backgroundColor: '#F4F5F5' }}
            size="small"
            name={"upgradePeriod"}
            value={row.upgradePeriod || 0}
            onChange={e => 
              handleMonthChange(index,e.target.name,e.target.value)
            }
          >
            {Array.from({ length: 13 }, (_, index) => index).map((v, i) => (
              <MenuItem key={i} value={v} style={{ fontSize: '10px'}}
                disabled={ 
                  reduce ?
                    dayjs(row?.previousExpiry).subtract(v*30,'day')
                    .isBefore(dayjs())
                    : false
                }
              >
                {v} Month
              </MenuItem>
            ))}
          </Select>
        </TableCell>
        : null
      }
      <TableCell
        padding={'none'}
        component="td"
        scope="row" align='center'
      >
        <Typography variant='body02-medium'>
          {
            inviteAction === AccessControl.UPGRADE 
            ? 
              dayjs(row.previousExpiry).isValid()
              ? formatDate(row.previousExpiry, "DD-MM-YYYY") : "-"
            :
              dayjs(row.onboardedAt).isValid()
              ? formatDate(row.onboardedAt, "DD-MM-YYYY") : "-"
          }
        </Typography>
      </TableCell>
      <TableCell
        padding={'none'}
        component="td"
        scope="row" align='center'
      >
        <Typography variant='body02-medium'>
          {
            dayjs(row.accessExpiresOn).isValid() 
            ? formatDate(row.accessExpiresOn, "DD-MM-YYYY") : "-"
            }
        </Typography>
      </TableCell>
      {
        isPayPerUser ?
        <TableCell padding={'none'} component="td" scope="row" align='center'>
          <Typography variant='body02-medium' 
            sx={!showUserCredits && sx.blurCredits}
          >
            {row.credits}
          </Typography>
        </TableCell>
        : null
      }
      <TableCell width={20} align="center" padding='0px'>
        <Actions index={index} row={row} />
      </TableCell>
    </MuiTableRow>
  );
}

function SimpleTable({
  cols = [],
  rows = [],
  onCellEdit = () => { },
  handleMonthChange= () => { },
  Actions,
  TableContainerProps = {},
  reduce = false,
  isPayPerUser = false,
  inviteAction,
  showUserCredits,
  errorRows
}) {
  return (
    <TableContainer component={Paper} {...TableContainerProps}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" >
        <TableHead>
          <MuiTableRow>
            {cols.map((col, i) => {
              if(col){
                return (
                  <TableCell 
                    style={{ padding: '5px', whiteSpace: 'nowrap' }} 
                    key={i} align='center'
                  >
                    <Typography variant='body02-bold'>
                      {col}
                    </Typography>
                  </TableCell>
                )
              }
            })}
            {Actions && <TableCell align="center"></TableCell>}
          </MuiTableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              row={row}
              index={index}
              onCellEdit={onCellEdit}
              handleMonthChange={handleMonthChange}
              Actions={Actions}
              inviteAction={inviteAction}
              reduce={reduce}
              isPayPerUser={isPayPerUser}
              showUserCredits={showUserCredits}
              errorRows={errorRows}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
};

export default SimpleTable;
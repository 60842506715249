import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ShadowBox from 'components/ShadowBox';
import { formatDate } from 'utils';
import { useParams } from 'react-router-dom';
import { getBatchUserMappings } from 'services';
import { SimpleSelect } from 'components/CustomSelectFields';
import { WhiteBox } from 'components/AppShell';

const useStyles = makeStyles(theme=>({
  label: {
    ...theme.typography['h6-medium'],
    color: theme.palette.neutral['clr-900']
  }
}));

function Label(props) {
  const classes = useStyles();
  return <span className={classes.label}>{props.children}&nbsp;</span>;
}

function StudentInfo({ student, selectedBatch, setSelectedBatch }) {
  const { id } = useParams();
  const [batches, setBatches] = React.useState([]);

  React.useEffect(() => {
    if (id) {
      (async () => {
        const { batchUserMappings } = await getBatchUserMappings({
          user: id, include: 'batch'
        });

        setBatches(
          batchUserMappings
            .filter(bum => !bum?.batch?.forPathway)
            .map(bum => ({
              name: bum?.batch?.name,
              _id: bum?.batch?._id,
            }))
        );
      })();
    }
  }, [id]);

  React.useEffect(()=>{
    setSelectedBatch(batches[batches.length-1]?._id || '');
  }, [batches])

  return (
    <WhiteBox display="flex" alignItems="stretch" gap={2} mt={6}>
      <Avatar
        src={student.profileUrl}
        sx={{ width: 90, height: 90, border: '1px solid lightgrey' }}
      >
        {student?.name?.charAt(0)}
      </Avatar>
      <Box display='flex' flexDirection='column' gap={2} flexGrow={2} ml={1}>
        <Typography variant="h4-medium">{student?.name}</Typography>
        <Typography variant='h6-medium' color='neutral.clr-600'>
          <Label>Roll No:</Label>{student?._id}
        </Typography>
        <Typography variant='h6-medium' color='neutral.clr-600'>
          <Label>Email:</Label>{student?.email}
        </Typography>
        <Typography variant='h6-medium' color='neutral.clr-600'>
          <Label>Last Active:</Label>
          {student?.metadata?.lastActive ?
            formatDate(student?.metadata?.lastActive, 'DD-MMM-YYYY, hh:mma') :
            "NA"}
        </Typography>
      </Box>
      <Box display='flex' justifyContent='flex-end' flexGrow={1} maxHeight={200} overflow="hidden">
        <Box px={1} pb={1}>
          <SimpleSelect
            label={'Select batch'}
            options={batches}
            value={selectedBatch}
            disabled={batches.length === 0}
            onChange={(e)=> { setSelectedBatch(e.target.value)}}
          />
        </Box>
      </Box>
    </WhiteBox>
  );
}

export default StudentInfo;
import React from 'react';

import GoogleSheetImportDialog from 'dialogs/GoogleSheetImport';
import CustomButton from './CustomButtons';

function GSheetImportButton({ sheetProps = {}, buttonText }) {
  const [open, setOpen] = React.useState(false);

  const toggleOpen = React.useCallback(() => setOpen(o => !o), []);

  const onSuccess = (results) => {
    sheetProps?.onSuccess(results);
    setOpen(false);
  };

  return (
    <>
      <CustomButton
        onClick={toggleOpen}
        variant='outlined'
        startIcon={(
          <img
            src={'https://assets.languify.in/images/g-sheet-icon.png'}
            alt="GSheetIcon"
            width={20}
            style={{ margin: 4 }}
          />
        )}
      >
        {buttonText || "Import from Google Sheet"}
      </CustomButton>
      <GoogleSheetImportDialog
        open={open}
        onClose={toggleOpen}
        {...sheetProps}
        onSuccess={onSuccess}
      />
    </>
  );
}

export default GSheetImportButton;
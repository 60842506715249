import React from 'react';
import { Bar } from 'react-chartjs-2';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip,
  PointElement,
  LineElement,
} from 'chart.js';

import CenterFlexBox from 'components/CenterFlexBox';
import { BlueSwitch, PurpleSwitch } from 'components/Switch';
import {
  getInterviewTemplateQuestionScores,
} from 'services';
import { InterviewTemplateTypes } from 'editors/TemplateEditor/constants';
import { SimpleSelect } from 'components/CustomSelectFields';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  PointElement,
  LineElement,
);

const useStyles = makeStyles((theme) => ({
  tooltip: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '8px',
    border: "1px solid #F0F0F0",
    boxShadow: '5px 3px 13px 0px #4747491A',
    padding: theme.spacing(1,4),
    transform: "all 200ms",
    pointerEvents: 'none',
    width: 300,
  },
  tooltipHeading: {
    ...theme.typography['body01-bold'],
    textTransform: "capitalize",
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
  },
  tooltipData: {
    padding: theme.spacing(1,5),
    width: '100%',
    display: "flex",
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tooltipDataValue: {
    ...theme.typography['body01-semiBold'],
  },
  tooltipDataLabel: {
    ...theme.typography['body01-medium'],
    color: theme.palette.neutral['clr-800']
  },
  changeChip: {
    width: 60,
    borderRadius: 12,
    padding: theme.spacing(1, 2),
    ...theme.typography['body02-bold'],
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  increment: {
    backgroundColor: theme.palette.success['clr-100'],
    color: theme.palette.success['clr-700'],
  },
  decrement: {
    backgroundColor: theme.palette.danger['clr-100'],
    color: theme.palette.danger['clr-700'],
  },
  performanceLink: {
    color: "#FFFFFF",
    background: theme.palette.primary.main,
    borderRadius: 12,
    fontWeight: 400,
    fontSize: "10px",
    padding: theme.spacing(1,2),
  },
  graphRoot: {
    width: "100%",
    background: '#FFFFFF',
    border: "1px solid lightgrey",
    borderRadius: '8px',
    padding: theme.spacing(4),
    textAlign: 'center',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
}));

function QuestionsAnalytics({ template = {} }) {
  const classes = useStyles();

  const { id } = useParams();

  const [attempt, setAttempt] = React.useState('');
  const [attemptOptions, setAttemptOptions] = React.useState([{ _id: '', name: 'Select' }]);
  const [attemptNoWiseQuestionsScore, setAttemptNoWiseQuestionsScore] = React.useState([]);
  const [questionsAnalytics, setQuestionsAnalytics] = React.useState([]);
  const [legendStates, setLegendStates] = React.useState({
    speech: true, content: true
  });

  const GraphOptions = {
    responsive: true,
    aspectRatio: 5,
    maintainAspectRatio: true,
    interaction: { mode: 'index', intersect: false },
    plugins: {
      legend: { display: false },
      title: { display: false, text: 'Performance' },
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: function (context) {
          const { chart } = context;
          // Tooltip Element
          let tooltipEl = document.getElementById('chartjs-tooltip');

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.classList.add(classes.tooltip);
            chart.canvas.style.position = 'relative';
            chart.canvas.parentNode.appendChild(tooltipEl);
          }
          tooltipEl.innerHTML = '';

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          const dataIndex = tooltipModel.dataPoints?.[0].dataIndex

          const data = questionsAnalytics[dataIndex];

          const prevData = attemptNoWiseQuestionsScore[(attempt - 1)]?.scores?.[dataIndex];

          let contentChange, commChange, contPC, commPC;

          if (prevData) {
            contentChange = data.content - prevData.content;
            contPC = prevData.content === 0 ? 0 :
              Math.round((contentChange * 100) / prevData.content);

            commChange = data.communication - prevData.communication;
            commPC = prevData.communication === 0 ? 0 :
              Math.round((commChange * 100) / prevData.communication);
          }

          const heading = document.createElement('p');
          heading.className = classes.tooltipHeading;
          heading.innerHTML = `Q.${dataIndex + 1} ${data?.question?.question.slice(0,27)}...`;

          tooltipEl.appendChild(heading);

          const dataContainer = document.createElement('div');
          dataContainer.style.width = '100%';

          let innerHTML = `
            <div class='${classes.tooltipData}'>
            <div class='${classes.tooltipDataLabel}'>Speech</div>
            <div class='${classes.tooltipDataValue}'>${data.communication || 0}</div>
            <div>
              <div class='${classes.changeChip} ${commChange < 0 ? classes.decrement : classes.increment}'>
                ${(Math.abs(commPC) || 0) === 0 ? "" : Math.abs(commPC) + '%'}&nbsp;&nbsp;<sup>
                ${commChange < 0 ? "&darr;" : (commChange > 0 ? "&uarr;" : "&dash;")}
                </div>
            </div>
            </div>
            <div class='${classes.tooltipData}'>
            <div class='${classes.tooltipDataLabel}'>Content</div>
            <div class='${classes.tooltipDataValue}'>${data.content || 0}</div>
            <div>
              <div class='${classes.changeChip} ${contentChange < 0 ? classes.decrement : classes.increment}'>
                ${(Math.abs(contPC) || 0) === 0 ? "" : Math.abs(contPC) + '%'}&nbsp;&nbsp;<sup>
                ${contentChange < 0 ? "&darr;" : (contentChange > 0 ? "&uarr;" : "&dash;")}
                </div>
            </div>
            </div>
            </div>`;

          dataContainer.innerHTML = innerHTML;

          tooltipEl.appendChild(dataContainer);

          const { offsetLeft: positionX, offsetTop: positionY } = context.chart.canvas;

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.zIndex = 10000000000;
          tooltipEl.style.left = positionX + tooltipModel.caretX - 250 + 'px';
          tooltipEl.style.top = positionY + tooltipModel.caretY - 140 + 'px';
        }
      }
    },
    scales: { yAxis: { min: 0, max: 100, } }
  };

  React.useEffect(() => {
    getInterviewTemplateQuestionScores(id)
      .then((scoresByAttemptNo) => {
        if (scoresByAttemptNo.length > 0) {
          setAttemptNoWiseQuestionsScore(scoresByAttemptNo);
          setAttemptOptions(
            new Array(scoresByAttemptNo.length)
              .fill('')
              .map((x, i) => ({ _id: i.toString(), name: `Attempt ${i + 1}` }))
          );
          setAttempt((scoresByAttemptNo.length ? 0 : '').toString());
        }
      }).catch(console.error);
  }, [id]);

  React.useEffect(() => {
    if (attemptNoWiseQuestionsScore.length > 0 && attempt !== '')
      setQuestionsAnalytics(attemptNoWiseQuestionsScore[+attempt].scores);
  }, [attempt, attemptNoWiseQuestionsScore]);

  const handleChange = (e) => setAttempt(e.target.value);

  const datasets = React.useMemo(() => {
    const _datasets = [];

    if (legendStates.speech) {
      _datasets.push({
        label: 'Communication',
        data: questionsAnalytics.map(qs => qs.communication),
        backgroundColor: 'rgba(184, 172, 246, 1)', 
        hoverBackgroundColor: '#8270DB', 
      });
    }
    if (legendStates.content) {
      _datasets.push({
        label: 'Content',
        data: questionsAnalytics.map(qs => qs.content),
        backgroundColor: '#9DE4F2',
        hoverBackgroundColor: '#52B8CC', 
      });
    }

    return _datasets;
  }, [questionsAnalytics, legendStates]);

  const labels = React.useMemo(() => {
    const _labels = questionsAnalytics.map((x, i) => `Q.${i + 1}`);

    if (_labels.length < 20) {
      for (let x = _labels.length; x < 20; x++) _labels.push('');
    }

    return _labels;
  }, [questionsAnalytics]);

  const handleLegendChange = (event) => {
    setLegendStates((ls) => ({
      ...ls, [event.target.name]: event.target.checked
    }));
  };

  return (
    <>
      <CenterFlexBox justifyContent="space-between" mb='20px'>
        <Box display='flex' flexDirection='column' gap='4px' width='80%'>
          <Typography variant='h4-medium'>
            Question wise Performance
          </Typography>
          {
            template.type !== InterviewTemplateTypes.ASSIGNMENT ?
            <Typography variant="h6-medium" color='neutral.clr-600'>
              Select an attempt from the drop-down menu on the right to compare your question-wise performance against the average of your previous attempts.
            </Typography>
            : null
          }
        </Box>
        {
          template.type !== InterviewTemplateTypes.ASSIGNMENT ?
          <div>
            <SimpleSelect
              label="Attempt No."
              options={attemptOptions}
              value={attempt}
              onSelect={handleChange}
            /><br/>
            <Typography variant='body02-semiBold' color='neutral.clr-600'>
              No. of Students: {attemptNoWiseQuestionsScore?.[+attempt]?.noOfAttempts || 0}
            </Typography>
          </div>
          : null
        }
      </CenterFlexBox>

      <Box className={classes.graphRoot}>
        <Box textAlign="right">
          <FormControlLabel
            control={
              <BlueSwitch
                name="speech"
                checked={legendStates.speech}
                onChange={handleLegendChange}
                sx={{ "& .track": { background: '#007AFF', } }}
              />}
            label="Speech"
          /> &nbsp;&nbsp;
          <FormControlLabel
            control={
              <PurpleSwitch
                name="content"
                checked={legendStates.content}
                onChange={handleLegendChange}
              />}
            label="Content"
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="body02-semiBold" style={{ transform: "rotate(-90deg)" }}>
            Score
          </Typography>
          <div style={{ flexGrow: 1 }}>
            <Bar
              options={GraphOptions}
              data={{ labels, datasets }}
            />
          </div>
        </Box>
        <div>
          <Typography variant="body02-semiBold" mt={1}>
            Questions
          </Typography>
        </div>
      </Box>
    </>
  );
}

export default QuestionsAnalytics;
import React from 'react';
import Papa from "papaparse";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useSnackbar } from 'contexts';
import CustomButton from './CustomButtons';

function CSVReaderInput({
  onData, onError, inputProps, label = "Import CSV", tooltip
}) {
  const snackbar = useSnackbar();

  const changeHandler = (event) => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    const file = event.target.files?.[0];

    event.target.value = null;

    if (file)
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          // Parsed Data Response in array format
          if (onData) onData(results.data);
          snackbar.showSnackbar("Successfully imported the file!!")
        },
        error: (error) => {
          if (onError) onError(error);
          snackbar.showSnackbar(error.message, "error");
        },
      });
  };

  return (
    <CustomButton
      title={tooltip}
      color="primary"
      aria-label="upload csv file"
      component="label"
      variant="outlined"
      startIcon={<FileUploadIcon color="primary" />}
    >
      <input
        hidden
        type="file"
        name="csvFile"
        {...inputProps}
        accept=".csv"
        onChange={changeHandler}
        onClick={(e) => { e.target.value = null }}
      />
      {label}
    </CustomButton>
  );
}

export default CSVReaderInput;
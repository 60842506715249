import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import { getTemplateTags } from 'services';

const TagInfo = {
  keywords: { key: "KEYWORD", placeholder: "Add Keyword...", label: "keyword" },
  subTopics: { key: "SUB_TOPIC", placeholder: "Add Topic...", label: "topic" },
};


function TemplateTags({ type, tags, setTags, domain = "", max = 10, disabled }) {
  const [addSuggestion, setAddSuggestion] = React.useState(false);
  const [suggestions, setSuggestions] = React.useState(["new"]);

  const { placeholder, label, key } = TagInfo?.[type] || {};

  React.useEffect(() => {
    if (domain) {
      getTemplateTags({ domain, type: key }).then(({ templateTags }) => {
        if (templateTags.length > 0) {
          templateTags = templateTags.map(tt => tt.keyword);

          setSuggestions(Array.from(new Set(templateTags)));
        }
      })
    } else {
      setSuggestions(["new"]);
    }
  }, [domain]);

  const addTag = (tag) => {
    if (tags.includes(tag)) return;

    const newTags = [...tags, tag];

    setTags(newTags);
  };

  const deleteTag = (tag) => setTags(tags.filter(t => t !== tag));

  const handleSubmit = (e) => {
    e.preventDefault();

    if (tags.length >= max) return;

    const formData = new FormData(e.target);

    const _value = formData.get(type)?.trim();

    if (_value) addTag(_value);

    setAddSuggestion(false);
    e.target.reset();
  };

  return (
    <Box>
      <Stack
        direction="row"
        flexWrap="wrap"
        justifyContent="flex-start"
        alignItems="center"
        gap={0.5}
        py={1}
      >
        {tags.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            style={{fontSize: '16px', marginTop: '8px', marginBottom: '4px'}}
            variant="contained"
            color="primary"
            onDelete={() => { if (!disabled) deleteTag(tag) }}
          />
        ))}
        {tags.length < max && (
          addSuggestion ? (
            <form
              onSubmit={handleSubmit}
              style={{
                display: "inline-flex",
                alignItems: "center",
                border: "1px solid #02569D",
                borderRadius: 24,
                width: "fit-content",
                padding: "5px 10px",
                marginTop: '8px', marginBottom: '4px'
              }}
            >
              <input
                width={'100%'} height={'100%'}
                style={{
                  fontSize: 14,
                  border: 'none',
                  background: 'transparent'
                }}
                placeholder={placeholder}
                name={type}
                type="alphanum"
                onFocus={(e) => e.currentTarget.style.outline = 'none'}
              />
              <IconButton
                sx={{ p: 0, m: 0 }}
                color="primary"
                size="small"
                type='submit'
              >
                <CheckCircleRoundedIcon fontSize='small' />
              </IconButton>
              <IconButton
                sx={{ p: 0, m: 0 }}
                color="primary"
                size="small"
                onClick={() => setAddSuggestion(false)}
              >
                <CancelRoundedIcon fontSize='small' />
              </IconButton>
            </form>
          ) : (
            <Chip
              label={`Add ${label}`}
              variant="outlined"
              color="primary"
              disabled={disabled}
              onClick={() => setAddSuggestion(true)}
              onDelete={() => setAddSuggestion(true)}
              deleteIcon={<AddIcon />}
              style={{fontSize: '16px', marginTop: '8px', marginBottom: '4px'}}
            />
          )
        )}
      </Stack>

      {(
        <Box
          border="1px solid #02569D"
          p={2}
          borderRadius={1}
          position="relative"
        >
          <Typography mb={1} variant="body01-bold">
            Suggestions based on your selections
          </Typography>
          <Stack direction="row" flexWrap="wrap" justifyContent="flex-start" mt={4}>
            {suggestions.map((suggestion, index) => (
              <Chip
                clickable
                key={index}
                label={suggestion}
                variant="outlined"
                color="primary"
                style={{ marginRight: 4, marginBottom: 4, fontSize: '16px'}}
                onClick={() => { if (!disabled) addTag(suggestion) }}
              />
            ))}
          </Stack>
        </Box>)
      }
    </Box>
  );
}

export default TemplateTags;

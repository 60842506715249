import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { GridActionsCellItem } from '@mui/x-data-grid';
import makeStyles from "@mui/styles/makeStyles";
import Box from '@mui/material/Box';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import {
   LinearProgress, Stack, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Typography,
  IconButton
} from '@mui/material';
import Filter from './Filter';
import { useList } from "hooks/useList";
import Session from 'utils/Session';
import useEditorStore from 'editors/TemplateEditor/editorStore';
import Row from './Tables/Row';
import TableNoRowsOverlay from './TableNoRowsOverlay';
import CustomMenu from './CustomMenu';
import Pagination from './Pagination';
import { IconButton as CustomIconButton } from './CustomButtons';
import theme from 'theme';
import { DebouncedTextField } from './CustomFields';

const useStyles = makeStyles((theme) => ({
  tableHeader: { 
    backgroundColor: theme.palette.shades['clr-white-900'], 
    color: theme.palette.shades['clr-black-900'], 
    fontWeight: 600 
  },
  iconButtonRoot: {
    color: theme.palette.shades['clr-white-900'],
    '&:hover': {
      backgroundColor: "#a8a3a347"
    },
  },
  headerRow: {
    height: '40px',
    padding: 0,
    '& td': {
      whiteSpace: 'nowrap',
      padding: theme.spacing(1.5,0)
    }
  },
  emptyDialogBox: {
    width: "100%",
    height: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '30px 0px',
  },
  filterItems: {
    width: '100%', 
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  colContent: {
    display: 'flex', 
    gap: theme.spacing(1), alignItems: 'center'
  },
  paginationRoot: {
    display: 'flex', justifyContent: 'space-between', 
    marginTop: theme.spacing(5)
  },
  tableCell: {
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.neutral['clr-100'],
  },
}));

const sx = {
  stickyColumn: {
    position: "sticky",
    zIndex: 10,
    padding: '0px',
    paddingLeft: '5px'
  },
  actionColumn: {
    whiteSpace: 'nowrap',
    position: "sticky",
    right: '-1px',
    zIndex: 10,
    display: 'flex-end',
    width: '40px',
  },
  analyticsColumn: {
    whiteSpace: 'nowrap',
    position: "sticky",
    right: '20px',
    zIndex: 10,
  },
  sortIcon: {
    fontSize: '15px',
    cursor: 'pointer',
    display: 'flex',
    color: '#135EBF'
  },
}

export const getListActions = ({
  params, editItem, setActiveItemId, setArchiveItemData, entity, editActionIcon, customActions,
  hideEditAction, hideDeleteAction, editActionLabel, rowSelection, hideArchiveAction = true,
  personalizedList, rowSelectionModel = []
}) => {
  let actions = [];
  if (Array.isArray(customActions)) {
    actions = customActions.map(ca => (
      <GridActionsCellItem
        title={ca.label}
        icon={ca.renderIcon(params)}
        label={ca.label}
        onClick={() => ca.onClick(params)}
      />
    ))
  }

  if (!hideEditAction && editItem && !personalizedList && Session?.permissions?.[entity]?.UPDATE) {
    actions.push(<GridActionsCellItem
      title={editActionLabel || 'Edit'}
      icon={editActionIcon || <CustomIconButton
                                variant='outlined'
                                style={{
                                          border : `1px solid ${theme.palette.primary.main}`,
                                          padding : theme.spacing(1)
                                      }}
                              >
                                <img src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/edit-3.svg' alt='edit'/>
                              </CustomIconButton>}
      label={editActionLabel || "Edit"}
      onClick={(e) => editItem(e, params.id)}
    />);
  }

  if (
    !hideDeleteAction && setActiveItemId &&
    Session?.permissions?.[entity]?.DELETE
  ) {
    actions.push(<GridActionsCellItem
      title='Delete'
      icon={<DeleteIcon color='error' />}
      label="Delete"
      onClick={() => setActiveItemId(params.id)}
    />);
  }

  if (rowSelection) {
    actions.push(
      <GridActionsCellItem
        title='Select'
        icon={(
          <input type='checkbox' name={params?.row?.name} value={params?.id}
            checked={rowSelectionModel.includes(params?.id)} height={'100%'} width={'100%'}
            readOnly={true}
          />
        )}
        label="Select"
        sx={{ padding: '0px' }}
      />
    )
  }

  return actions;
}

const SortList = ({ column, handleSort }) => {
  const classes = useStyles();
  const [sortOrder, setSortOrder] = React.useState(false);

  const handleSorting = () => {
    setSortOrder(!sortOrder);
    handleSort([{ field: column?.field, sort: sortOrder ? "desc" : "asc", sortBy: column?.sortBy }])
  }

  return (
    <>
      {
        sortOrder
          ?
          <IconButton size='small' classes={{ root: classes.iconButtonRoot }}>
            <ArrowDownwardIcon onClick={handleSorting} sx={sx.sortIcon} />
          </IconButton>
          :
          <IconButton size='small' classes={{ root: classes.iconButtonRoot }}>
            <ArrowUpwardIcon onClick={handleSorting} sx={sx.sortIcon} />
          </IconButton>
      }
    </>
  )
}

const getMenuListActions = ({ params, menuOptions, archiveItem }) => {
  const options = [];

  menuOptions.forEach(({ label, id, onClick }) => {
    let _onClick = onClick;
    if (['archive', 'unarchive'].includes(id)) _onClick = () => archiveItem(params.id);
    if (['edit', 'duplicate', 'setting'].includes(id) && params?.row?.type === 'follow_up') return;
    options.push({ label, onClick: _onClick });
  });

  return <CustomMenu params={params} options={options} />
}

function DynamicTable({
  width='100%', entity, columns, useListProps, actions, hideEditAction, hideDeleteAction, hideArchiveAction,
  noRowsMessage, editActionLabel, showRowSelectCheckbox = false, filters = [], menuOptions,
  filterOptions = {}, editActionIcon = null, height = '345px',
  quickSearchField = 'name', onRowClick = () => { }, refreshData = false,
  dummyRows = [], showDummyRows = false, rowSelectionModel = [], setRefresh = () => {}, setRowSelectionModel = () => { },
  personalizedList, ...props
}) {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const setPageFilters = useEditorStore((state) => state.setPageFilters);
  const pathname = window.location.pathname;

  const {
    loading, items, setItems, total, editItem, deleteItem, archiveItem, setFilters, setSortModel,
    setPageNo, pageNo, pageSize, filters: appliedFilters, refresh
  } = useList({ initialPageNo: 1, initialPageSize: 10, ...useListProps });

  const handleFilterChange = ({ field, value }) => {
    console.log("DEBUG:", field, value);
    if (value) {
      setFilters({ ...appliedFilters, [field]: value });
      
      setPageFilters(
        { [pathname]: { ...appliedFilters, [field]: value } }
      );
    }
    else {
      setFilters((filters) => {
        const newFilters = { ...filters };

        delete newFilters[field];
        setPageFilters({ [pathname]: newFilters });

        return newFilters;
      });
    }
  };

  const handleSortModelChange = (newModel) => {
    if (newModel[0]) {
      const { field, sort, sortBy } = newModel[0];
      setSortModel({ orderBy: sortBy? sortBy : field, order: sort });
    }
  };

  const handleRowSelection = (data) => {
    if (rowSelectionModel?.includes(data._id)) {
      setRowSelectionModel(rowSelectionModel.filter(r => r !== data._id));
    }
    else {
      setRowSelectionModel([...rowSelectionModel, data._id]);
    }
  }

  const handleSelectAll = () => {
    if (rowSelectionModel.length === items.length) {
      setRowSelectionModel([]);
    } else {
      const allRowIds = items.map((row) => row._id);
      setRowSelectionModel(allRowIds);
    }
  };

  const _columns = React.useMemo(() => {
    if (!columns?.length) return [];
    const allColumns = columns?.map(({ id, ...c }) => ({
      flex: 1,
      headerClassName: classes.tableHeader,
      field: id,
      ...c,
    }));

    if (showRowSelectCheckbox) {
      allColumns.unshift({
        field: 'select',
        label: (
          <input type='checkbox' name='selectAll'
            checked={items?.length && rowSelectionModel?.length === items?.length}
            onChange={handleSelectAll}
          />
        ),
        type: 'select',
        align: 'center',
        disableSorting: true,
        maxWidth: '20px',
        getActions: (params) => getListActions({
          params, rowSelection: true, rowSelectionModel
        })
      })
    }

    if (actions) {
      allColumns.push({
        field: 'actions',
        label: 'Actions',
        type: "actions",
        align: 'center',
        disableSorting: true,
        minWidth: 'content-fit',
        actionGrid: 1,
        headerClassName: classes.tableHeader,
        getActions: (params) => getListActions({
          params, editItem, entity, editActionIcon,
          customActions: actions, hideEditAction, hideDeleteAction: true,
          editActionLabel, hideArchiveAction , personalizedList
        }),
      });
    }

    if (menuOptions) {
      allColumns.push({
        field: 'menu',
        label: '',
        type: "actions",
        align: 'right',
        minWidth: 10,
        disableSorting: true,
        headerClassName: classes.tableHeader,
        getActions: (params) => getMenuListActions({ params, menuOptions, archiveItem })
      });
    }

    return allColumns;
  }, [columns, hideEditAction, hideDeleteAction, rowSelectionModel]);

  React.useEffect(() => {
    if (searchParams) {
      for (const { field, type } of filters) {
        const value = searchParams.get(field)
        
        if (value && (type !== 'select' || (filterOptions[field]))) {
          handleFilterChange({ field, value })
        }
      }
    }
  }, [searchParams, filters, filterOptions]);

  React.useEffect(() => {
    if (refreshData) refresh();
    setRefresh(false)
  }, [refreshData])

  return (
    <Box>
      {(quickSearchField || filters.length > 0) && (
        <Filter
          filters={filters}
          filterOptions={filterOptions}
          onChange={handleFilterChange}
          appliedFilters={appliedFilters}
          quickSearchField={quickSearchField}
          personalizedList={personalizedList}
        >
          <Box className={classes.filterItems}>
            {quickSearchField && <DebouncedTextField
              placeholder="Search..."
              startIcon
              onChange={(value) => handleFilterChange({
                field: quickSearchField, value
              })}
            />}
            {
              props.newFilter &&
              props.newFilter
            }
          </Box>
        </Filter>
      )}
      {/* table */}
      <Box py={4} style={{ width }}>
        {
          <Box style={{ visibility: loading ? 'visible' : 'hidden', padding: '0px 0.5px' }}>
            <LinearProgress />
          </Box>
        }
        <TableContainer sx={{ border: '1px solid #E1E2E3', minHeight: height}}>
          <Table stickyHeader size="small" aria-label="a dense table">
          
            {/* table header */}
            <TableHead>
              <TableRow className={classes.headerRow}>
                {_columns?.length &&
                  _columns?.map((col, index) => {
                    return (
                      <TableCell
                        className={classes.tableCell}
                        align={col?.align || 'start'}
                        key={index}
                        sx={
                          col?.type === 'actions'
                            ? sx.actionColumn
                            : index === 0
                              ? { ...(sx.stickyColumn), left: 0 }
                              : (index === 1 && showRowSelectCheckbox)
                                ? { ...(sx.stickyColumn), left: '25px' } 
                                : col?.fixed
                                  ? { ...(sx.analyticsColumn) } : null 
                        }
                        style={{
                          minWidth: col?.minWidth,
                          maxWidth: col?.maxWidth,
                        }}
                      >
                        <Box
                          className={classes.colContent}
                          justifyContent={col?.align || 'start'}
                        >
                          <Typography variant='body01-bold'>
                            {col?.label}
                          </Typography>
                          <span>
                            {!col?.disableSorting &&
                              <SortList column={col} handleSort={handleSortModelChange} />
                            }
                          </span>
                        </Box>
                      </TableCell>
                    )
                  })
                }
              </TableRow>
            </TableHead>

            {/* table rows */}
            {
              <TableBody>
                {
                  showDummyRows && items.length === 0 ?
                    dummyRows.map((item, index) => (
                      <Row
                        key={index}
                        rowData={item}
                        index={index}
                        columns={_columns}
                        getActions={actions}
                      />
                    ))
                    :
                    items.map((item, index) => (
                      <Row
                        key={index}
                        rowData={item}
                        rowNo={index}
                        columns={_columns}
                        getActions={actions}
                        handleRowSelection={handleRowSelection}
                        showRowSelectCheckbox={showRowSelectCheckbox}
                        rowSelectionModel={rowSelectionModel}
                      />
                    ))
                }
              </TableBody>
            }
          </Table>
          {
            items.length === 0 && !showDummyRows ?
              <Box className={classes.emptyDialogBox} >
                <TableNoRowsOverlay
                  message={
                    appliedFilters.domain || appliedFilters.batch
                      ? 'Nothing to show for applied filters!'
                      : noRowsMessage
                  }
                />
              </Box>
              : null
          }
        </TableContainer>

        <Box className={classes.paginationRoot}>
          <Typography variant='h6-medium' color='neutral.clr-500' display='flex' gap='4px'>
            Showing 
            <Typography variant='h6-medium' color='neutral.clr-900'>{items?.length || 0}</Typography> of 
            <Typography  variant='h6-medium' color='neutral.clr-900'>{total}</Typography> entries
          </Typography>
          <Stack spacing={2}>
            <Pagination
              count={Math.ceil(total / pageSize)}
              setPage={setPageNo}
              page={pageNo}
            />
          </Stack>
        </Box>
      </Box>

    </Box>
  );
}

export default DynamicTable;

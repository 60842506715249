import * as React from "react";
import { Divider } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Menu, Typography } from "@mui/material";
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import CustomButton from "components/CustomButtons";
import { useSnackbar } from "contexts";
import { exportToCsv } from "utils";

const useStyles = makeStyles(theme=>({
  exportHeads: {
    ...theme.typography['body02-bold'],
    marginLeft: '-5px'
  },
  filterBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
    fontSize: '13px',
    backgroundColor: '#F1F2F6',
    padding: '5px 8px',
    borderRadius: '5px',
    color: '#464545'
  },
}))

const sx = {
  selectButton: {
    fontSize: '14px',
    textTransform: 'capitalize',
    color: "#000",
    whiteSpace: 'nowrap',
    border: '1px solid #F1F2F6'
  },
  exportButton: {
    color: "#fff",
    backgroundColor: "#02569D",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
    '&:hover': {
      backgroundColor: "#02569D",
    }
  },
  filterClose: {
    display: 'flex',
    fontSize: '20px',
    alignItems: 'center',
    cursor: 'pointer'
  },
};

const exportDataTypes = [
  { field: 'cumulativeAverage', label: 'Cumulative Average' },
  { field: 'individualAttempts', label: 'Individual Attempts' }
]

export const FilterList = ({ columns, filters, setFilters, handleDeselectFilter }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilters = (e) => {
    if (filters.includes(e.target.name)) {
      setFilters(filters.filter((f) => f !== e.target.name))
    }
    else {
      setFilters([...filters, e.target.name])
    }
  }

  return (
    <>
    <Box display='flex' justifyContent='space-between' mb='16px' alignItems='center'>
      <Box display='flex' alignItems='center'>
          <Typography variant="body01-bold" mr='16px'> Overview </Typography>
          <Box display='flex' gap='12px' alignItems='center' flexWrap='wrap'>
            {
              columns?.map((col, index) => {
                if (filters.includes(col?.field) && !col.fixed) {
                  return (
                    <Box key={index} className={classes.filterBox}>
                      <Typography variant="body01-semiBold" color='neutral.clr-700'>
                        {col?.label}
                      </Typography>
                      <CloseIcon
                        sx={sx.filterClose}
                        onClick={() => handleDeselectFilter(col.field)}
                      />
                    </Box>
                  )
                }
                else return null;
              })
            }
          </Box>
        </Box>
        <Box position='relative'>
          <Button
            sx={sx.selectButton}
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <Typography variant="body01-bold">
              Category: {filters.length > 1 ? "Multiple" : filters[0]}
            </Typography>
            <ArrowDropDownIcon fontSize="14px"/>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Box id='categories'>
              <Box display='flex' flexDirection='column' gap='4px' px='6px' fontSize='12px'>
                {
                  columns.map((col, index) => {
                    return (
                      <label key={index} htmlFor={`filter${col.field}`}>
                        <input
                          checked={filters.includes(col.field)}
                          onChange={handleFilters}
                          disabled={col.fixed}
                          type='checkbox'
                          id={`filter${col.field}`}
                          name={col.field} 
                        />
                        <Typography variant='body02-semiBold'>
                          {col.label}
                        </Typography>
                      </label>
                    )
                  })
                }
              </Box>
            </Box>
          </Menu>
        </Box>
      </Box>
    </>
  )
}

export const ExportList = ({
  columns, rows, exportRows, forTemplateAnalytics, filters, setFilters, exportByAttempts = false,
  useItemProps, ...props
}) => {
  const snackbar = useSnackbar();
  const classes = useStyles();

  const [exportType, setExportType] = React.useState(exportDataTypes[0].field);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilters = (e) => {
    if (filters.includes(e.target.name)) {
      setFilters(filters.filter((f) => f !== e.target.name));
    } else {
      setFilters([...filters, e.target.name]);
    }
  };

  const handleExportTypeChange = (e) => {
    const newExportType = e.target.name;
    setExportType(newExportType);
    
    if (newExportType === 'individualAttempts') {
      setFilters(prevFilters => prevFilters.filter(f => f !== 'finishedAttempts' && f !== 'unfinishedAttempts'));
    } else {
      setFilters(prevFilters => {
        if (!prevFilters.includes('finishedAttempts')) prevFilters.push('finishedAttempts');
        if (!prevFilters.includes('unfinishedAttempts')) prevFilters.push('unfinishedAttempts');
        return prevFilters.filter(f => f !== 'question_wise_analysis');
      });
    }
  };

  const handleExport = () => {
    let exportColumns = _columns;
    if (exportType === 'individualAttempts') {
      exportColumns = [..._columns, { field: 'question_wise_analysis', label: 'Question Wise Analysis' }];
      exportColumns = exportColumns.filter(col => col.field !== 'finishedAttempts' && col.field !== 'unfinishedAttempts');
    } else {
      exportColumns = _columns.filter(col => col.field !== 'question_wise_analysis');
    }
    exportToCsv(exportColumns, forTemplateAnalytics, rows, exportRows, filters, useItemProps?.name, exportType === 'individualAttempts');
    handleClose();
    snackbar.success("Exported performance successfully!!");
  };

  const _columns = React.useMemo(() => {
    let filteredColumns = columns.filter((col) => !col?.disableExport);
    if (exportType === 'individualAttempts') {
      filteredColumns = filteredColumns.filter(col => col.field !== 'finishedAttempts' && col.field !== 'unfinishedAttempts');
    }
    return filteredColumns;
  }, [columns, exportType]);

  return (
    <Box position='relative'>
      <CustomButton
        startIcon={<IosShareOutlinedIcon />}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size="medium"
      >
        Export Performance
      </CustomButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Box id='exportFilters' style={{ padding: '0px 15px 5px 15px' }}>
          <Box display='flex' flexDirection='column' gap='4px' fontSize='12px'>
            {
              exportByAttempts ?
                <>
                  <label className={classes.exportHeads}>
                    DataTypes
                  </label>
                  {
                    exportDataTypes.map((col, index) => {
                      return (
                        <label key={index} htmlFor={col.field} >
                          <input
                            checked={exportType === col.field}
                            onChange={handleExportTypeChange}
                            type='checkbox'
                            id={col.field}
                            name={col.field} 
                          />
                          <Typography variant="body02-semiBold" color='neutral.clr-700'>
                            {col.label}
                          </Typography>
                        </label>
                      )
                    })
                  }
                </>
                : null
            }
            <label className={classes.exportHeads} style={{ marginTop: '5px' }}>
              Customize Column
            </label>
            {_columns.map((col, index) => (
              <label key={index} htmlFor={col.field}>
                <input
                  checked={filters.includes(col.field)}
                  onChange={handleFilters}
                  disabled={col.fixed}
                  type='checkbox'
                  id={col.field}
                  name={col.field}
                />
                <Typography variant="body02-semiBold" color='neutral.clr-700'>
                  {col.label}
                </Typography>
              </label>
            ))}
            {exportType === 'individualAttempts' && (
              <>
                <Divider style={{ margin: '5px 0' }} />
                <label htmlFor='question_wise_analysis'>
                  <input
                    checked={filters.includes('question_wise_analysis')}
                    onChange={handleFilters}
                    type='checkbox'
                    id='question_wise_analysis'
                    name='question_wise_analysis'
                  />
                  <Typography variant="body02-semiBold" color='neutral.clr-700'>
                    Question Wise Analysis
                  </Typography>
                </label>
              </>
            )}
          </Box>
          <Box width='100%' display='flex' justifyContent='flex-end' mt='10px'>
            <Button style={{ padding: '0px', fontSize:'14px' }} onClick={handleExport}>
              EXPORT
            </Button>
          </Box>
        </Box>
      </Menu>
    </Box>
  );
};

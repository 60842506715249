export const AverageScoresBgColors = [
  '#E2483C', '#F5B847', '#009970',
];

export const AverageScoresBorderColors = [
  '#FF2D55', '#FFCC00', '#41D7A1',
];

export const AverageScoreLegends = [
  { color: '#FF2D55BB', label: '0-30' },
  { color: '#FFCC00BB', label: '31-60' },
  { color: '#41D7A1BB', label: '61-100' },
];

export const AttendanceLegends = [
  { color: '#FF2D55BB', label: '0%-30%' },
  { color: '#FFCC00BB', label: '31%-60%' },
  { color: '#41D7A1BB', label: '61%-100%' },
];

export const toggleOptions = [
  { _id: 'averageScore', label: 'Average Score' },
  { _id: 'attendanceStats', label: 'Attendance Stats' }
];
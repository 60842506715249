import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Typography from '@mui/material/Typography';

import CenterFlexBox from 'components/CenterFlexBox';
import { Box } from '@mui/material';

ChartJS.register(ArcElement);
ChartJS.register(Tooltip);

const styles = {
  root: {
     padding: '8px 32px',
  },
  container: { position: 'relative', },
  percentageContainer: {
    width: "100%", position: "absolute", top: 0, height: "100%",
    pointerEvents: 'none',
  },
  percentage: {
    fontSize: '1.5vw', fontWeight: 600, width: 'fit-content',
  },
  percentageSign: { fontSize: "70%" },
};

function ScoreCard({
  tooltip, label, score, color, description, style = {}, showTooltip = false,
  isPercentage = false,
}) {
  const data = React.useMemo(() => {
    const colors = [color, `${color}22`];
    return {
      datasets: [
        {
          data: [+score, 100 - score],
          backgroundColor: colors,
          borderColor: colors,
          borderWidth: 0,
          cutout: "75%"
        },
      ],
    };
  }, [score, color]);

  return (
    <div style={{ ...styles.root, ...style }} title={tooltip}>
      <div style={styles.container}>
        <Doughnut
          data={data}
          options={{
            plugins: { tooltip: showTooltip, legend: false },
            maintainAspectRatio: false,
            responsive: false
          }}
          width={192}
          height={192}
        />
        <CenterFlexBox sx={styles.percentageContainer}>
          <p style={styles.percentage}>
            {score}{isPercentage && <span style={styles.percentageSign}>%</span>}
          </p>
        </CenterFlexBox>
      </div>
      <Box textAlign='center' mt={2}>
        <Typography variant='h6-medium'>
          {label}
        </Typography>
        {description && (
          <Typography variant='body01-regular'>
            {description}
          </Typography>
        )}
      </Box>
    </div>
  );
}

export default ScoreCard;
import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ShadowBox from 'components/ShadowBox';
import { formatDate } from 'utils';


const styles = {
  capitalizeText: { textTransform: 'capitalize' }
};

function Detail({ label, children, capitalize }) {
  return (
    <Typography
      variant='h6-medium'
      color='neutral.clr-900'
      style={capitalize ? styles.capitalizeText : {}}
    >
      <span>{label}:&nbsp;</span>{children}
    </Typography>
  );
}

function BatchInfo({ batch }) {
  
  return (
    <ShadowBox display="flex" alignItems="stretch" gap={2} m={0} 
      style={{paddingTop: '16px', paddingBottom: '16px'}}
    >
      <Box display='flex' flexDirection='column' gap='8px'>
        <Typography variant="h4-medium">
          {batch?.name}&nbsp;
        </Typography>
        <Detail label="Total students" capitalize>
          <Typography variant='h6-medium' color='neutral.clr-600'>
            {batch?.studentCount?.toString()}
          </Typography>
        </Detail>
        <Detail label="Assessment Uploaded">
          <Typography variant='h6-medium' color='neutral.clr-600'>
            {batch?.templateCount?.toString()}
          </Typography>
        </Detail>
        <Detail label="Created On" >
          <Typography variant='h6-medium' color='neutral.clr-600'>
            {formatDate(batch?.createdAt, 'Do MMM, YYYY (dddd)')}
          </Typography>
        </Detail>
      </Box>
    </ShadowBox>
  );
}

export default BatchInfo;
import React from 'react';
import { Outlet, Route, Routes } from "react-router-dom";

import AppShell from 'components/AppShell';
import TemplateList from 'lists/Templates';
import TemplateEditor from 'editors/TemplateEditor';
import TemplatePerformance from 'screens/TemplatePerformance';


function Templates(props) {
  return (
    <Routes>
      <Route path="/" element={<TemplatesLayout />}>
        <Route
          index
          element={
            <React.Suspense fallback={<>...</>}>
              <TemplateList />
            </React.Suspense>
          }
        />

        <Route
          path="/:id/performance"
          element={
            <React.Suspense fallback={<>...</>}>
              <TemplatePerformance />
            </React.Suspense>
          }
        />

        <Route
          path="/:id"
          element={
            <React.Suspense fallback={<>...</>}>
              <TemplateEditor />
            </React.Suspense>
          }
        />

        <Route
          path="/draft/:draftId"
          element={
            <React.Suspense fallback={<>...</>}>
              <TemplateEditor />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
}

export default Templates;

function TemplatesLayout() {
  return (
    <AppShell>
      <Outlet />
    </AppShell>
  );
}
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import ResetIcon from '@mui/icons-material/Replay';
import { Box } from '@mui/material';

const useStyles = makeStyles(theme => ({
  sliderContainer: {
    display: 'flex',
    flexDirection:'column',
    flex: '1',
    minWidth: "45%",
    marginLeft: 16,
    marginRight: 16,
  },
  slider: {
    padding: '22px 0px',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
}));

function SlideControl({ label, onChange, ...props }) {
  const classes = useStyles();

  return (
    <div className={classes.sliderContainer}>
      <Box display='flex' gap='4px' alignItems='center'>
        <Typography variant="body01-bold">
          {label}
        </Typography>
        <IconButton
          size="small"
          title="Reset"
          onClick={() => onChange(props.defaultValue)}
        >
          <ResetIcon color="primary" />
        </IconButton>
      </Box>
      <Slider
        aria-labelledby={label}
        classes={{ root: classes.slider }}
        onChange={(e, value) => onChange(value)}
        sx={{
          color: '#9DE4F2', 
          '& .MuiSlider-thumb': { backgroundColor: '#52B8CC',
            '&.Mui-focusVisible': { boxShadow: '0 0 0 8px rgba(210, 243, 250, 0.5)' },
           },
          '& .MuiSlider-track': { backgroundColor: '#9DE4F2',height: 4 }, 
          '& .MuiSlider-rail': { backgroundColor: '#DEF7FC',height: 4 }, 
        }}
        {...props}
      />

    </div>
  );
}

export default SlideControl;
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { getScoresByUserId, getUserAttemptRate } from "services";
import ScoreCard from "components/ScoreCard";
import { getAverageScore } from "utils";
import { WhiteBox } from "components/AppShell";

function Scores({ student, selectedBatch }) {
  const [scores, setScores] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      if (student._id && selectedBatch) {
        try {
          const {
            communicationOverall,
            contentOverall,
            highestAverage,
            lowestAverage
          } = await getScoresByUserId("inprep", student._id, selectedBatch);
          const { totalTemplates, templateAttempted } =
            await getUserAttemptRate("inprep", student._id, selectedBatch);

          setScores([
            {
              isPercentage: true,
              label: "Attempt Rate",
              color: "#8F7EE7",
              score:
                totalTemplates > 0
                  ? Math.round((templateAttempted / totalTemplates) * 100)
                  : 0,
            },
            {
              label: "Average Score",
              score: getAverageScore(communicationOverall, contentOverall) || 0,
              color: "#52B8CC",
            },
            {
              label: "Highest Score",
              color: "#009970",
              score: Math.round(highestAverage) || 0,
            },
            {
              label: "Lowest Score",
              color: "#E2483C",
              score: Math.round(lowestAverage) || 0,
            },
          ]);
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, [student, selectedBatch]);

  return (
    <>
     <WhiteBox>
        <Box display='flex' flexDirection='column' gap='4px'>
        <Typography variant="h4-medium" >
          Overall Performance
        </Typography>
        <Typography variant="h6-medium" color='neutral.clr-600'>
           Get an overview of students’ performance
        </Typography>

        </Box>
        <Box
          display="flex"
          alignItems="stretch"
          gap="30px"
          mt="2em"
        >
          {scores.map((score, i) => (
            <ScoreCard
              key={i}
              {...score}
              style={{
                maxWidth: "25%",
                backgroundColor: "white",
                paddingTop: 16,
              }}
            />
          ))}
        </Box>
     </WhiteBox>
    </>
  );
}

export default Scores;

import * as React from 'react';
import { styled } from '@mui/styles';
import MUIAutocomplete from '@mui/material/Autocomplete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import makeStyles from '@mui/styles/makeStyles';
import Popper from '@mui/material/Popper';

import TextField from 'components/TextField';

export const useStyles = makeStyles(theme => ({
    textField: {
        display: 'block',
        borderRadius: theme.spacing(1),
        '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor : theme.palette.neutral['clr-600']
        },
        '& .MuiOutlinedInput-input': {
          fontSize: '16px',
          color: `${theme.palette.neutral['clr-700']} !important`,
          height: 'fit-content',
          ...theme.typography['body01-medium'],
          padding: theme.spacing(3,2),
          backgroundColor: theme.palette.shades['clr-white-900']
        },
        '& .MuiOutlinedInput-root': {
          '&:hover' : {
            backgroundColor: theme.palette.shades['clr-white-900']
          },
          height: '44px !important',
          borderRadius: theme.spacing(1),
          fontSize: 16,
          '& fieldset': {
            borderColor: theme.palette.neutral['clr-200'],
          },
          '&:hover fieldset': {
            borderColor: theme.palette.neutral['clr-200'],
          },
          '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary['clr-500'],
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.neutral['clr-200'],
            '& fieldset': {
              border: '2px solid',
              borderColor: theme.palette.neutral['clr-400'],
            },
          }
        }
    }
}))

const StyledPopper = styled(Popper)(({ theme }) => ({
    zIndex: theme.zIndex.modal,
    boxShadow: '0px 2px 8px 0px #00000029',
    "& .MuiAutocomplete-listbox": {
      maxHeight: 300,
      overflowY: 'auto',
      padding: '0px',
      "& .MuiAutocomplete-option": {
        ...theme.typography['body01-medium'],
        height: '40px',
        borderBottom: `1px solid ${theme.palette.neutral['clr-200']}`,
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',            
        textOverflow: 'ellipsis',      
        position: 'relative',            
  
        "&:hover": {
          backgroundColor: theme.palette.primary['clr-50'],
          overflow: 'visible',           
          whiteSpace: 'normal',          
          zIndex: 1,                    
          padding: '0 10px',             
          boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)', 
        },
        
        "&.Mui-selected": {
          backgroundColor: `${theme.palette.primary['clr-400']} !important`,
          color: theme.palette.shades['clr-white-900'],
        },
        "&.Mui-selected:hover": {
          backgroundColor: theme.palette.primary['clr-400'],
          color: theme.palette.shades['clr-white-900'],
        },
      },
    },
  }));
  
const defaultOption = { id: 'na', option: 'Select' };

export default function AutoComplete({
    defaultValue = 'na',
    options = [],
    value,
    onChange = () => { },
    size = 250,
    label = '',
    helperText,
    readOnly,
    ...props
}) {
    const [_value, setValue] = React.useState(defaultValue);

    const classes = useStyles();

    const handleChange = (e, newValue) => {
        onChange(e, newValue);
        setValue(newValue?.id);
    };

    const iconComponent = (iconProps) => (
        <ExpandMoreIcon className={`${iconProps.className} ${classes.icon}`} />
      );

    const menuProps = React.useMemo(() => ({
        classes: {
          paper: classes.paper,
          list: classes.groupList
        },
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        MenuListProps: {
          style: {
            ...props.menuStyle
          }
        }
      }), [classes.paper, classes.groupList, props.menuStyle]);

    return (
        <>
            <MUIAutocomplete
                readOnly={readOnly}
                disableClearable
                options={options}
                value={options.find((option) => (
                    value || _value) !== undefined &&
                    option?.id === (value || _value)) || defaultOption
                }
                PopperComponent={StyledPopper}
                groupBy={(option) => option.group}
                inputProps={{ className: classes.input }}
                getOptionLabel={(option) => option.option || option.label}
                sx={{ width: size }}
                size='small'
                MenuProps={menuProps}
                onChange={handleChange}
                IconComponent={iconComponent}
                renderInput={(params) =>
                    <TextField {...params}
                        label={label}
                        onKeyDown={(e) => e.stopPropagation()}
                        className={classes.textField}
                          variant="outlined"
                    />
                }
                {...props}
            />
            {(helperText) && <span style={{
                color: '#e7574a', marginLeft: 15, fontSize: 12,
            }}>
                {helperText}
            </span>}
        </>
    );
}
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';
import makeStyles from "@mui/styles/makeStyles";
import { useNavigate } from 'react-router-dom';

import { signOut } from 'services';
import CustomButton from './CustomButtons';
import ImageViewer from './ImageViewer';

const useStyles = makeStyles(theme=>({
  confirmationRoot: {
    padding: theme.spacing(2,5), 
    position: 'relative'
  },
  confirmationHead: { 
    boxShadow: 'none', display: 'flex', alignItems: 'center',
    justifyContent: 'space-between', 
    borderBottom: '1px solid',
    borderColor: theme.palette.neutral['clr-200']
  },
  actions: {
    display: 'flex', width: '100%',
    '&>*': {
      width: '50%'
    }
  }
}));

export function LogoutConfirmationDialog({
  openDialog, onClose = undefined,
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  
  const [open, setOpen] = React.useState(openDialog);
  
  const handleClose = () => {
    onClose();
    setOpen(false);
  };

    const handleLogout = () => {
        signOut();
        navigate(`/auth/login`);
    };


  React.useEffect(()=>{
    setOpen(openDialog);
  },[openDialog]);

  return (
    <Dialog
      onClose={handleClose}
      maxWidth={'sm'}
      fullWidth={true}
      minHeight='200px'
      open={open}
    >
      <Box className={classes.confirmationRoot}>
        <Box className={classes.confirmationHead}>
          <Typography variant='h5-medium' color='danger.clr-700'>
            Do you want to logout?
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon style={{ fontSize: '30px' }}/>
          </IconButton>
        </Box>
        {/* content */}
        <DialogContent style={{ display: 'flex', justifyContent: 'center' }}>
          <ImageViewer
            src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/logout.png'
            alt='logout'
            width={200}
            height={200}
          />
        </DialogContent>

        <DialogActions className={classes.actions}>
          <CustomButton onClick={handleClose} variant='outlined'>
           No, Go Back
          </CustomButton>
          <CustomButton onClick={handleLogout} variant='contained'>
           Yes, Log out
          </CustomButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
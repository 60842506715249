import { useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import React from "react";

import VerticalProgressBar from "components/BatchAnalytics/VerticalProgressBar";
import { getAttendanceAndAverageScoreStats } from "services";
import DoughnutGraph from "components/Home/DoughnutGraph";
import ShadowBox from "components/ShadowBox";
import { getAverageScore } from "utils";
import {
  AverageScoresBgColors,
  AverageScoresBorderColors,
} from "components/Home/constants";

const useStyles = makeStyles(theme=> ({
  leftContainer: {
    width: '50%', 
    display:'flex', justifyContent: 'center', flexDirection: 'column'
  },
  rightContainer: {
    width:'50%',
    display: 'flex', justifyContent: 'center'
  },
  rangeBox: {
    display: "flex",
    marginTop: theme.spacing(4), 
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0,12),
    gap: theme.spacing(2.5)
  },
  batchAvgBox: {
    display: 'flex', justifyContent: 'center', 
    marginTop: theme.spacing(5)
  },
  avgBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(2,3),
    border: '1px solid',
    borderRadius: theme.spacing(1),
    borderColor: theme.palette.neutral['clr-200'],
    backgroundColor: theme.palette.neutral['clr-50']
  },
  legends: {
    display: "flex",
    alignItems: "center",
    justifyContent: 'space-between'
  }
}));

const AverageScoreLegends = [
  { range: "0-30", color: "#E2483C" },
  { range: "31-60", color: "#F5B847" },
  { range: "61-100", color: "#009970" },
];

function BatchStats(props) {
  const classes = useStyles();
  const { id } = useParams();

  const [attandanceStats, setAttandanceStats] = React.useState({
    "0-30": 0,
    "31-60": 0,
    "61-100": 0,
  });
  const [averageScoreStats, setAverageScoreStats] = React.useState({
    "0-30": 0,
    "31-60": 0,
    "61-100": 0,
  });
  const [averageScore, setAverageScore] = React.useState(0);
  const [averageAttandance, setAverageAttandance] = React.useState(0);

  const totalStudents = React.useMemo(()=>{
    let total = 0;
    AverageScoreLegends.map(data=>
      total += averageScoreStats[data.range]
    )

    return total;
  },[averageScoreStats, AverageScoreLegends])

  React.useEffect(() => {
    getAttendanceAndAverageScoreStats(id)
      .then(({ attendanceStats, averageScoreStats }) => {
        setAttandanceStats(attendanceStats);
        setAverageScoreStats(averageScoreStats);
        setAverageAttandance(attendanceStats.averageAttendance);
        setAverageScore(() =>
          getAverageScore(
            averageScoreStats?.score?.communication || 0,
            averageScoreStats?.score?.content || 0
          )
        );
      })
      .catch(console.errror);
  }, [id]);

  return (
    <ShadowBox width="65%" display="flex" justifyContent="space-evenly">
      <Box className={classes.leftContainer}>
        <DoughnutGraph
          rootStyle={{ padding: 0 }}
          color="#0263FF"
          data={[
            attandanceStats["0-30"] || 0,
            attandanceStats["31-60"] || 0,
            attandanceStats["61-100"] || 0,
          ]}
          bgColors={AverageScoresBgColors}
          borderColors={AverageScoresBorderColors}
          labels={["No. of Students", "No. of Students", "No. of Students"]}
          title="Attendance"
          description="Avg attempt rate of all students in batch"
          score={averageAttandance || 0}
          isPercentage
        />
        <Box className={classes.rangeBox}>
          <Typography variant="body01-bold" textAlign='center' whiteSpace='nowrap'>
            No. of students in the attendance range
          </Typography>
          {AverageScoreLegends.map((x,index) => (
            <Box className={classes.legends} >
              <Box display="flex" alignItems="center" gap='10px'>
                <Box width={20} bgcolor={x.color} p={0.5} borderRadius={8} />
                <Typography variant='body02-bold'>
                  {x.range}
                </Typography>
              </Box>
              <Typography variant="body02-bold" color='neutral.clr-700'>
                {attandanceStats[x.range]}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box className={classes.rightContainer}>
        <Box height='60%'>
          <VerticalProgressBar
            averageScoreStats={averageScoreStats || {}}
            averageScoreLegends={AverageScoreLegends || []}
            xAsix={'Score Range'}
            yAsix={'No. of Students'}
            totalStudents={totalStudents}
          />
          <Box className={classes.batchAvgBox}>
            <Box className={classes.avgBox}>
              <Typography variant="h5-bold">
                {averageScore || 0}
              </Typography>
              <Typography variant="h6-medium">
                Batch Average
              </Typography>
              <Typography variant='body01-semiBold' color='neutral.clr-600'>
                Score consisting of {totalStudents} students
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </ShadowBox>
  );
}

export default BatchStats;

import * as Yup from "yup";
import Box from '@mui/material/Box';

import { useState } from 'react';
import { useFormik } from 'formik';
import { useSnackbar } from 'contexts';
import { changePassword } from 'services';
import { PasswordField } from "components/CustomFields";
import { CustomLoadingButton } from "components/CustomButtons";

function Password(props) {
    const snackbar = useSnackbar();
    const [loading, setLoading] = useState(false);

    const {
        values,
        handleSubmit,
        handleChange,
        resetForm,
        errors,
        touched
    } = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: "",
            confirmedPassword: "",
        },
        validationSchema: new Yup.ObjectSchema({
            currentPassword: Yup.string()
                .min(8, "Min 8 characters required")
                .max(50, "Max 50 characters allowed")
                .required("Required"),
            newPassword: Yup.string()
                .min(8, "Min 8 characters required")
                .max(50, "Max 50 characters allowed")
                .notOneOf([Yup.ref('currentPassword'), null], "New Password has to be different than current Password")
                .required("Required"),
            confirmedPassword: Yup.string()
                .min(8, "Min 8 characters required")
                .max(50, "Max 50 characters allowed")
                .oneOf([Yup.ref('newPassword'), null], "Does not match with new password!")
                .required('Required'),
        }),
        onSubmit: async (values) => {
            try {
                setLoading(true);

                await changePassword({
                    currentPassword: values.currentPassword,
                    newPassword: values.newPassword
                });

                resetForm();
                snackbar.showSnackbar("Password is changed successfully!");
            } catch (error) {
                snackbar.showSnackbar(error.message, 'error');
            } finally {
                setLoading(false);
            }
        },
    });

    return (
        <Box>
            <form onSubmit={handleSubmit}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap:'4px'
                }}>

                <PasswordField
                    label='Current password*'
                    id="currentPassword"
                    name="currentPassword"
                    value={values.currentPassword}
                    onChange={handleChange}
                    spellCheck="false"
                    error={touched.currentPassword && errors.currentPassword}
                    helperText={(touched.currentPassword && errors.currentPassword)}
                    sx={{ width: '50%', minWidth : '380px'}}
                /> <br />

                <PasswordField
                    label='New password*'
                    id="newPassword"
                    name="newPassword"
                    value={values.newPassword}
                    onChange={handleChange}
                    spellCheck="false"
                    error={touched.newPassword && errors.newPassword}
                    helperText={(touched.newPassword && errors.newPassword)}
                    sx={{ width: '50%',  minWidth : '380px'}}
                /> <br />

                <PasswordField
                    label='Confirmed Password*'
                    id="confirmedPassword"
                    name="confirmedPassword"
                    value={values.confirmedPassword}
                    onChange={handleChange}
                    spellCheck="false"
                    error={touched.confirmedPassword && errors.confirmedPassword}
                    helperText={(touched.confirmedPassword && errors.confirmedPassword)}
                    sx={{ width: '50%',  minWidth : '380px'}}
                />

                <CustomLoadingButton
                    sx={{ mt: 4, textTransform: "none", width: 100 }}
                    loading={loading}
                    variant="contained"
                    type='submit'
                    size="small"
                >
                    Submit
                </CustomLoadingButton>
            </form>
        </Box>
    );
}

export default Password;
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";

import Sidebar from "./Sidebar";

const useStyles = makeStyles(theme=>({
    contentRoot: {
        flex: "auto",
        marginLeft: "0px",
        marginTop: "0",
        width: "calc(100vw - 242px)",
        padding: "0px 0px 32px 242px",
    },
    whiteBox: {
        padding: theme.spacing(7, 10),
        borderRadius: '4px',
        boxShadow: '0px 0px 4px 0px #00000026',
        backgroundColor: theme.palette.shades['clr-white-900'],
        [theme.breakpoints.down(725)]: {
          padding: theme.spacing(5, 3),
        }
      },
}));

export default function AppShell({ children }) {
    const classes = useStyles();

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                height: "calc(100vh - 0px)",
                marginTop: "0px",
            }}
        >
            <Sidebar />
            <Box className={classes.contentRoot} >
                <div style={{ padding: "0px 20px" }}>
                    {children}
                </div>
            </Box>
        </Box>
    );
}

export function WhiteBox({ children, ...props }) {
    const classes = useStyles();
  
    return (
      <Box className={classes.whiteBox} {...props}>
        {children}
      </Box>
    );
  }
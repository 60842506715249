import React from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

function FieldWrapper(props) {
    return (
        <Grid
            item
            xs={6}
            pb={props?.pb || 2}
            px={props?.px || 2}
            maxWidth={props?.width ? props.width : 300}
            {...(props?.GridItemProps || {})}
            style={{ flexGrow: props?.flexGrow ? 1 : undefined }}
        >
            <Typography variant='h6-semiBold' pb={props?.paddingBottom||""} >
                {props.title}
            </Typography><br/>
            <Typography variant='body02-medium' color='neutral.clr-800'>
                {props.description}
            </Typography>
            {props.children}
            <FormHelperText error>{props.error && props.helperText}</FormHelperText>
        </Grid>
    );
}

export default FieldWrapper;
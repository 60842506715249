import React from 'react';

import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';

import AssessmentAnalytics from 'components/StudentPerformance/AssessmentAnalytics';
import Scores from 'components/StudentPerformance/Scores';
import StudentInfo from 'components/StudentPerformance/StudentInfo';
import { getUserById } from 'services';

function StudentPerformance(props) {
  const { id } = useParams();

  const [student, setStudent] = React.useState({});
  const [selectedBatch, setSelectedBatch] = React.useState('');

  React.useEffect(() => {
    (async () => {
      const user = await getUserById(id);

      setStudent(user);
    })();
  }, [id]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight='calc(100vh - 56px)'
      maxWidth='calc(100% - 8px)'
      pb={6}
      gap={4}
    >
      <StudentInfo student={student} setSelectedBatch={setSelectedBatch} selectedBatch={selectedBatch}/>
      <Scores student={student} selectedBatch={selectedBatch}/>
      <AssessmentAnalytics student={student} selectedBatch={selectedBatch}/>
    </Box>
  );
}

export default StudentPerformance;
import React from "react";
import { Bar } from "react-chartjs-2";
import { useNavigate, useParams } from "react-router-dom";
import { orderBy } from 'lodash';

import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip,
  PointElement,
  LineElement,
} from "chart.js";

import CenterFlexBox from "components/CenterFlexBox";
import { BlueSwitch, PurpleSwitch } from "components/Switch";
import { getRecentTemplatesAnalytics } from "services";
import { calculatePrevAverage, getPercentageChange } from "utils";
import { InterviewTemplateTypes } from "editors/TemplateEditor/constants";
import { SimpleSelect } from "components/CustomSelectFields";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  PointElement,
  LineElement
);

const useStyles = makeStyles((theme) => ({
  tooltip: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: "8px",
    border: "1px solid #F0F0F0",
    boxShadow: "5px 3px 13px 0px #4747491A",
    padding: theme.spacing(1,4),
    transform: "all 200ms",
    pointerEvents: "none",
    width: 300,
  },
  tooltipHeading: {
    ...theme.typography['body01-bold'],
    textTransform: "capitalize",
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
  },
  tooltipData: {
    padding: theme.spacing(1,5),
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tooltipDataValue: {
    ...theme.typography['body01-semiBold'],
  },
  tooltipDataLabel: {
    ...theme.typography['body01-medium'],
    color: theme.palette.neutral['clr-800']
  },
  changeChip: {
    width: 60,
    borderRadius: 12,
    padding: theme.spacing(1, 2),
    ...theme.typography['body02-bold'],
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  increment: {
    backgroundColor: theme.palette.success['clr-100'],
    color: theme.palette.success['clr-700'],
  },
  decrement: {
    backgroundColor: theme.palette.danger['clr-100'],
    color: theme.palette.danger['clr-700'],
  },
  performanceLink: {
    color: theme.palette.shades['clr-white-900'],
    background: theme.palette.primary.main,
    borderRadius: 12,
    fontWeight: 400,
    fontSize: '10px',
    padding: theme.spacing(1,2),
  },
  emptyState: {
    marginBottom: theme.spacing(2.5)
  },
  graphContainer: {
    width: "100%",
    background: theme.palette.shades['clr-white-900'],
    border: "1px solid lightgrey",
    borderRadius: '8px',
    padding: theme.spacing(2),
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  }
}));

const AssessmentOptions = [
  { _id: "all", name: "All" },
  { _id: "assignment", name: "Assignment" },
  { _id: "practice", name: "Practice" },
  { _id: "follow_up", name: "Follow Up" },
];

const AssessmentTags = {
  all : 'practice assessments and assignments',
  assignment: 'assignment',
  practice: 'practice',
  follow_up: 'follow-up'
}

function RecentTemplatesAnalytics(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();

  const [templateType, setTemplateType] = React.useState("all");
  const [templatesAnalytics, setTemplatesAnalytics] = React.useState([]);
  const [legendStates, setLegendStates] = React.useState({
    speech: true,
    content: true,
  });

  const getGraphToolTip = (context) => {
    const { chart } = context;
    // Tooltip Element
    let tooltipEl = document.getElementById("chartjs-tooltip");

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.id = "chartjs-tooltip";
      tooltipEl.classList.add(classes.tooltip);
      chart.canvas.style.position = "relative";
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
    tooltipEl.innerHTML = "";

    // Hide if no tooltip
    const tooltipModel = context.tooltip;
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set caret Position
    tooltipEl.classList.remove("above", "below", "no-transform");
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add("no-transform");
    }

    const dataIndex = tooltipModel.dataPoints?.[0].dataIndex;
    const dataToConsider = templatesAnalytics.filter(
      (data,i) => (data.type !== InterviewTemplateTypes.FOLLOW_UP && data?.totalAttempts > 0 
        && i<dataIndex)
      )

    const currData = templatesAnalytics[dataIndex] || {};
    const prevAvg = calculatePrevAverage(dataToConsider, dataIndex);

    let contentChange, commChange, contPC, commPC;

    if (prevAvg) {
      contentChange = currData.contentOverall - prevAvg.contentOverall;
      commChange = currData.communicationOverall - prevAvg.communicationOverall;

      contPC = prevAvg.contentOverall === 0 ? 0 :
        getPercentageChange(currData.contentOverall, prevAvg.contentOverall);
      commPC = prevAvg.communicationOverall === 0 ? 0 :
        getPercentageChange(currData.communicationOverall, prevAvg.communicationOverall);
    }

    const heading = document.createElement("p");
    heading.className = classes.tooltipHeading;
    heading.innerHTML = currData.templateName + ` (${currData.type})`;

    tooltipEl.appendChild(heading);

    const dataContainer = document.createElement("div");
    dataContainer.style.width = "100%";

    dataContainer.innerHTML = `
    ${(templatesAnalytics[dataIndex].totalAttempts !== 0) ? 
    
    `<div class='${classes.tooltipData}'>
    <div class='${classes.tooltipDataLabel}'>Speech</div>
    <div class='${classes.tooltipDataValue}'>${
      currData.communicationOverall || 0
    }</div>
    <div>
    ${templatesAnalytics[dataIndex].type !== InterviewTemplateTypes.FOLLOW_UP ?
      `
      <div class='${classes.changeChip} ${commChange < 0 
        ? classes.decrement : classes.increment}'
      >
      ${(templatesAnalytics[dataIndex].totalAttempts === 0) || ( Math.abs(commPC) || 0) === 0 
          ? "" : Math.abs(commPC) + '%'
        }&nbsp;&nbsp;<sup>
      ${(templatesAnalytics[dataIndex].totalAttempts === 0 || dataIndex === 0 ||
        (Math.abs(contPC) || 0) === 0 ) 
        ? "-" : commChange < 0 ? "&darr;" : (commChange > 0 ? "&uarr;" : "&dash;")
        }
      </div>
      `
      :
      `<div></div>`
    }
    </div>
    </div>
    <div class='${classes.tooltipData}'>
    <div class='${classes.tooltipDataLabel}'>Content</div>
    <div class='${classes.tooltipDataValue}'>${currData.contentOverall || 0}</div>
    <div>
    ${templatesAnalytics[dataIndex].type !== InterviewTemplateTypes.FOLLOW_UP ?
      `
      <div class='${classes.changeChip} ${contentChange < 0 
        ? classes.decrement : classes.increment}'
      >
        ${(templatesAnalytics[dataIndex].totalAttempts === 0) || (Math.abs(contPC) || 0) === 0 
            ? "" : Math.abs(contPC) + '%'
          }&nbsp;&nbsp;<sup>
        ${(templatesAnalytics[dataIndex].totalAttempts === 0 || dataIndex === 0 || 
          (Math.abs(contPC) || 0) === 0 ) 
          ? "-" : contentChange < 0 ? "&darr;" : (contentChange > 0 ? "&uarr;" : "&dash;")
          }
      </div>
      `
      :
      `<div></div>`
    }
    </div>
    </div>`
    : 
    `
    <div class='${classes.emptyState}'>
      <div>
        <img src='https://assets.languify.in/images/noAttempts.svg' alt='no attempts yet'/>
      </div>
      <div> No Attempts yet</div>
    </div>
    `
    }
    `;

    tooltipEl.appendChild(dataContainer);

    const { offsetLeft: positionX, offsetTop: positionY } =
      context.chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.zIndex = 10000000000;
    tooltipEl.style.left = positionX + tooltipModel.caretX - 250 + "px";
    tooltipEl.style.top = positionY + tooltipModel.caretY - 140 + "px";
  };

  const GraphOptions = {
    responsive: true,
    aspectRatio: 5,
    maintainAspectRatio: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: { display: false },
      title: {
        display: false,
        text: "Performance",
      },
      tooltip: {
        enabled: false,
        position: "average",
        external: getGraphToolTip,
      },
    },
    onClick: (e) => {
      document.getElementById("chartjs-tooltip").style.opacity = 0;

      const index = e?.chart?.tooltip?.dataPoints?.[0]?.dataIndex;
      const tid = templatesAnalytics[index]._id;

      navigate(`/assessments/${tid}/performance`);
    },
    scales: { yAxis: { min: 0, max: 100 } },
  };

  React.useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const templates = await getRecentTemplatesAnalytics(id, templateType);
        
        const sortedTemplates = orderBy(templates, ['createdAt'], ['asc']);

        setTemplatesAnalytics(sortedTemplates);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTemplates();
  }, [id, templateType]);

  const datasets = React.useMemo(() => {
    const _datasets = [];

    if (legendStates.speech) {
      _datasets.push({
        label: "Communication",
        data: templatesAnalytics.map((qs) => qs.communicationOverall),
        backgroundColor: "#B8ACF6",
        hoverBackgroundColor: '#8270DB',
      });
    }
    if (legendStates.content) {
      _datasets.push({
        label: "Content",
        data: templatesAnalytics.map((qs) => qs.contentOverall),
        backgroundColor: "#9DE4F2",
        hoverBackgroundColor: '#52B8CC'
      });
    }

    return _datasets;
  }, [templatesAnalytics, legendStates]);

  const labels = React.useMemo(() => {
    const _labels = templatesAnalytics.map((x, i) => {
      let label = x.templateName.substr(0, 8);

      if (x.templateName.length > 8) label = label.concat("...");

      return label;
    });

    if (_labels.length < 10) {
      for (let x = _labels.length; x < 10; x++) _labels.push("");
    }

    return _labels;
  }, [templatesAnalytics]);

  const handleChange = (e) => setTemplateType(e.target.value);

  const handleLegendChange = (event) => {
    setLegendStates((ls) => ({
      ...ls,
      [event.target.name]: event.target.checked,
    }));
  };

  return (
    <>
      <CenterFlexBox justifyContent="space-between" mb='20px'>
        <Box display='flex' flexDirection='column' gap='4px' width='80%'>
          <Typography variant="h4-medium">
            Recent Analytics
          </Typography>
          <Typography variant="h6-medium" color='neutral.clr-600'>
            The graph tooltip shows changes in values for each assessment, comparing them with the cumulative average, 
            considering all prior&nbsp;
            {
              AssessmentTags[templateType]
            }
          </Typography>
        </Box>
        <SimpleSelect
          label="Assessment Type"
          options={AssessmentOptions}
          value={templateType}
          onChange={handleChange}
        />
      </CenterFlexBox>

      <Box className={classes.graphContainer}>
        <Box textAlign="right">
          <FormControlLabel
            control={
              <BlueSwitch
                name="speech"
                checked={legendStates.speech}
                onChange={handleLegendChange}
                sx={{ "& .track": { background: "#007AFF" } }}
              />
            }
            label="Speech"
          />{" "}
          &nbsp;&nbsp;
          <FormControlLabel
            control={
              <PurpleSwitch
                name="content"
                checked={legendStates.content}
                onChange={handleLegendChange}
              />
            }
            label="Content"
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Typography
            variant="body02-semiBold"
            style={{ transform: "rotate(-90deg)" }}
          >
            Score
          </Typography>
          <div style={{ flexGrow: 1 }}>
            <Bar options={GraphOptions} data={{ labels, datasets }} />
          </div>
        </Box>
        <div>
          <Typography variant="body02-semiBold" mt={1}>
            Assessments
          </Typography>
        </div>
      </Box>
    </>
  );
}

export default RecentTemplatesAnalytics;

import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const sx = {
  container: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
  },
  barcontainer: {
    backgroundColor: '#E8F0FD',
    position: 'relative',
    transform: 'translateY(-50%)',
    top: '50%',
    width: '48px',
    height: '100%',
    float: 'left',
    borderRadius: '5px 5px 0px 0px',
  },
  bar: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    boxSizing: 'border-box',
    animation: '$grow 1.5s ease-out forwards',
    transformOrigin: 'bottom',
    borderRadius: '5px 5px 0px 0px',
    display: 'flex',
    justifyContent: 'center',
  },
  '@keyframes grow': {
    from: {
      transform: 'scaleY(0)',
    },
  },
  totalTag: {
    position: 'absolute',
    fontSize: '11px',
    fontWeight: '550'
  },
  yAxis: {
    transform: 'rotate(-90deg)',
    left: '-35px',
    position: 'absolute'
  }
};

export const BarContainer = ({ height, color, total }) => {
  return (
    <Box sx={sx.barcontainer}>
      <Box
        sx={sx.bar}
        style={{
          height: `${height}%`,
          backgroundColor: `${color}`,
        }}
      >
        <Typography sx={{...sx.totalTag, top: height === 100 ? '2px' : '-15px',}}>
          { total > 0 && total }
        </Typography>
      </Box>
    </Box>
  );
};

const VerticalProgressBar = ({
  averageScoreStats, averageScoreLegends, xAsix, yAsix, totalStudents
}) => {

  return (
    <Box  display='flex' flexDirection='column' gap='15px'>
      <Box display="flex" alignItems="center" height='143px' position='relative'>
      <Typography
                        variant='body01-bold' 
                        color='neutral.clr-600'
                        component="div"
                        style={{ transform: "rotate(-90deg)" , position:'absolute' , top:70,left:-50}}
                    >
                       {yAsix}
                    </Typography>
        <Box sx={sx.container} width='100%'>
          {
            averageScoreLegends?.map((data,index)=>{
              return (
                <Box>              
                  <BarContainer 
                    height={(averageScoreStats[data.range]/(totalStudents))*100 || 0}
                    color={data.color}
                    total={averageScoreStats[data.range]}
                  /><br/>
                  <Typography variant='body02-semiBold'>
                    { data?.range }
                  </Typography>
                </Box>
              )
            })
          }
        </Box>
      </Box>
      <Box textAlign='center' mt={2} > 
        <Typography variant='body01-bold' color='neutral.clr-600'>
          { xAsix }
        </Typography>
      </Box>
    </Box>
  );
};

export default VerticalProgressBar;

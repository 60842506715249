import React from 'react';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import theme from "theme";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

import CustomButton from 'components/CustomButton';
import OptionChips from 'editors/TemplateEditor/OptionChips';
import FieldWrapper from './FieldWrapper';

export function DatePickerField({
    name,
    value,
    label,
    onChange,
    minDate,
    disabled,
    defaultValue,
    description,
    width
}) {
    const [options, setOptions] = React.useState([]);
    const [openDatePicker, setOpenDatePicker] = React.useState(false);

    React.useEffect(() => {
        let _options = [];

        if (name === "publishOn") {
            _options.push({
                label: "Now", value: defaultValue || dayjs().toISOString()
            });
            _options.push({
                label: "After 24 hrs",
                value: dayjs().add(24, 'hour').toISOString()
            });
        } else {
            _options.push({
                label: "Never",
                value: defaultValue || dayjs().set('year', 9999).endOf('year').toISOString(),
            });
            _options.push({
                label: "After 1 week",
                value: dayjs().add(1, 'week').endOf('day').toISOString()
            });
        }
        setOptions(_options);
        onChange(name, defaultValue);
    }, [name]);

    const handleOptionChange = (e) => onChange(name, e.target.value);

    return (
      <FieldWrapper 
        title={label} description={description} 
        width={width}  px={theme.spacing(0)}
        pb={theme.spacing(0)}
      >
        <Box display="flex" alignItems="center" width="100%">
          <OptionChips
            style={{
                width: "fit-content", marginLeft: 0, marginRight: 0
            }}
            name={name}
            value={value}
            onChange={handleOptionChange}
            options={options}
            disabled={disabled}
          />

          <CustomButton
            style={{ borderRadius: 500, marginLeft: 0 }}
            size="large"
            startIcon={<CalendarIcon />}
            variant={value ? "contained" : "outlined"}
            onClick={() => setOpenDatePicker(true)}
            disabled={disabled}
          >
            {value ? (
                dayjs(value).format("DD MMM YYYY h:mm A")
            ) : (
                "Pick date and time"
            )}
          </CustomButton>
          <div
            style={{
                visibility: 'hidden',
                overflow: "hidden",
                height: 0,
                width: 0,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDateTimePicker
                  disabled={disabled}
                  open={openDatePicker}
                  value={value}
                  onClose={() => setOpenDatePicker(false)}
                  onChange={(value) => onChange(name, value.toISOString())}
                  renderInput={(params) => <TextField {...params} />}
                  disablePast
                  minDate={minDate}
              />
            </LocalizationProvider>
          </div>
        </Box>
      </FieldWrapper>
    );
}

export default DatePickerField;

import React from 'react';
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles({
  container: {
    background: "#C5D8E8",
    minWidth: 100,
    height: 'fit-content',
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
  },
  slider: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "50%",
    height: "100%",
    background: "#02569D",
    position: "absolute",
    zIndex: 0,
    transition: "400ms ease-in-out",
    boxShadow: "0 0 4px 0px #02569D",
    "&>:hover": {
      background: "#02569Dee",
    }
  },
  content: {
    padding: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 50,
    width: "50%",
    background: "transparent",
    zIndex: 1,
    letterSpacing: 1,
    color: "#02569D",
    cursor: "pointer",
    transition: "400ms ease-in-out",
  }
});

function Toggle({
  width,
  height,
  br,
  option1Title, option2Title,
  forceChange,
  options = [],
  onChange = () => { },
  TypographyProps = {},
  ...props
}) {
  const classes = useStyles();
  const theme = useTheme();

  const [active, setActive] = React.useState(0);

  const handleToggle = (index) => () => {
    setActive((active) => active === index ? active : active === 0 ? 1 : 0);
  }

  React.useEffect(() => {
    typeof onChange === 'function' && onChange(options[active]);
  }, [active]);

  React.useEffect(() => {
    if (forceChange !== undefined) setActive(forceChange);
  }, [forceChange]);

  return (
    <Box width={width} {...props} >
      <Box className={classes.container}
        sx={{ borderRadius: br ? br : 3 }}
        style={{ height: height }}
      >
        <Box className={classes.slider}
          sx={{ left: active ? "50%" : "0%", borderRadius: br ? br : 3 }}>
        </Box>
        <Box className={classes.content}
          onClick={handleToggle(0)}
          style={{ color: active ? theme.palette.neutral.main : "white" }}
        >
          <Typography variant="body01-bold" {...TypographyProps}>
            {options?.[0]?.label || "Left"}
          </Typography>
        </Box>
        <Box className={classes.content}
          onClick={handleToggle(1)}
          style={{ color: active ? "white" : theme.palette.neutral.main }}
        >
          <Typography variant="body01-bold" {...TypographyProps}>
            {options?.[1]?.label || "Right"}
          </Typography>
        </Box>
      </Box >
    </Box >
  );
}

export default Toggle;
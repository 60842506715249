import React from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import MUIMenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { LogoutIcon } from './Icons';
import { LogoutConfirmationDialog } from './LogoutDialog';

const useStyles = makeStyles(theme => ({
  menuItem: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    transition: "200ms ease-in-out",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.neutral['clr-50'],
    },
  },
  menuItemNoHover: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
}));

function LogoutButton() {
  const theme = useTheme();
  const classes = useStyles();

  const [openConfirmation, setOpenConfirmation] = React.useState(false);

  return (
    <Box my={3} py={2.5} px={1} className={classes.menuItem}>
      <MUIMenuItem 
        onClick={() => setOpenConfirmation(true)} 
        className={classes.menuItemNoHover} 
      >
        <ListItemIcon>
          <LogoutIcon color={theme.palette.tertiary['clr-400']} />
        </ListItemIcon>
        <Typography
          variant='h6-semiBold'
          color='neutral.clr-600'
        >
          Logout
        </Typography>
      </MUIMenuItem>
      <LogoutConfirmationDialog
        openDialog={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
      />
    </Box>
  );
}

export default LogoutButton;
import { Box, Tooltip } from '@mui/material';
import { styled } from '@mui/system'; 
import React from 'react';
import { tooltipClasses } from '@mui/material/Tooltip';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme, positionStyle }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'transparent',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.neutral['clr-800'],
    color: theme.palette.shades['clr-white-900'],
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    padding: 7,
    maxWidth: 232,
    cursor: 'pointer',
    ...theme.typography['body02-bold'],
  },
  [`&.${tooltipClasses.popper}`]: {
    ...positionStyle,
  },
}));

const TitleTooltip = ({
  component,
  placement = 'top',
  children,
  positionStyle = {}, 
  onClick = ( ) => { },
  sx={},
  ...props
}) => {
  if (!component || component.length < 26) {
    return <Box sx={{ position: 'relative', ...sx }}>{children}</Box>;
  }
  return (
    <Box sx={{ position: 'relative', ...sx }} onClick={onClick}>
      <BootstrapTooltip
        title={component}
        positionStyle={positionStyle} 
        placement={placement}
        {...props}
      >
        {children}
      </BootstrapTooltip>
    </Box>
  );
};

export default TitleTooltip;

import React from 'react';
import { useField } from "formik";
import { styled, makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MuiSelect from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import InputBase from '@mui/material/InputBase';
import { Typography } from '@mui/material';

export const useStyles = makeStyles(theme => ({
  select: {
    minWidth: 200,
    background: 'white',
    color: grey[500],
    fontWeight: 200,
    fontSize: 12,
    borderStyle: 'none',
    borderWidth: 2,
    borderRadius: 12,
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    boxShadow: '0px 5px 8px -3px rgba(0,0,0,0.14)',
    "&:focus": {
      borderRadius: 12,
      background: theme.palette.shades['clr-white-900'],
      borderColor: grey[100]
    },
  },
  icon: {
    color: grey[900],
    right: 12,
    position: 'absolute',
    userSelect: 'none',
    pointerEvents: 'none'
  },
  paper: {
    borderRadius: '4px',
    marginTop: theme.spacing(2),
  },
  list: {
    maxHeight: 150, overflowY: "auto",
    padding: "6px 0px",
    "& li": {
      padding: "4px 0px 4px 8px",
      ...theme.typography['body01-medium']
    },
    "& li:hover": {
      borderLeft: '2px solid #02569D',
      background: theme.palette['neutral-50'],
    },
    "& li.Mui-selected": {
      background: theme.palette.primary['clr-400'],
      color: theme.palette.shades['clr-white-900'],
    },
    "& li.Mui-selected:hover": {
      background: theme.palette.primary['clr-400'],
      color: theme.palette.shades['clr-white-900'],
    }
  },
  mockList: {
    maxHeight: 150, overflowY: "auto",
    padding: theme.spacing(1.5,0),
    "& li": {
      fontWeight: 400,
      padding: "4px 0px 4px 8px",
      fontSize: 12,
      fontFamily: "Inter",
    },
    "& li:hover": {
      background: 'white',
      borderLeft: '2px solid #02569D'
    },
    "& li.Mui-selected": {
      background: '#E4E3E8',
    },
    "& li.Mui-selected:hover": {
      background: 'white',
    }
  }
}));

export const BootstrapInput = styled(InputBase)(({ theme , ...props }) => ({
  '& .MuiInputBase-input': {
    borderRadius: '4px',
    position: 'relative',
    backgroundColor: props?.background,
    ...theme.typography['body01-medium'],
    padding: '6px 10px',
    border: '1px solid #02569D',
    boxShadow: props?.boxShadow || '4px 4px 8px 0px rgba(0, 0, 0, 0.25)',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
  },
}));

export function Select({ options, helperText, defaultValue, background, boxShadow, ...props }) {
  const classes = useStyles();

  const menuProps = {
    classes: {
      paper: classes.paper,
      list: boxShadow ?  classes.mockList :  classes.list
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    getContentAnchorEl: null
  };

  return (
    <Box sx={{ minWidth: 120 }} >
      <Typography variant='body01-bold'
        letterSpacing={0.5} mb={'4px'}
      >
        {props.label}
      </Typography>
      <MuiSelect
        fullWidth
        labelId="simple-select-label"
        {...props}
        onChange={props.onChange}
        value={props.value}
        label={props.label}
        input={<BootstrapInput background={background}  boxShadow={boxShadow}/>}
        MenuProps={menuProps}
        classes={{ root: classes.select }}
        error={props.error}
        defaultValue={defaultValue}
      >
        {
          options.map((option) => (
            <MenuItem key={option._id} value={option._id}>{option.name}</MenuItem>
          ))
        }
      </MuiSelect>
      <FormHelperText error >{helperText}</FormHelperText>
    </Box>
  );
};

export function FormSelect({ options, ...props }) {
  const classes = useStyles();

  const [field, meta] = useField(props);

  const menuProps = {
    classes: {
      paper: classes.paper,
      list: classes.list
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    getContentAnchorEl: null
  };
  return (
    <Box sx={{ minWidth: 120, px: 1, my: 1, }} width="inherit">
      <FormControl
        fullWidth
        disabled={!options.length}
        size="small"
        variant='outlined'
      >
        <InputLabel
          id="simple-select-label"
          style={{ fontSize: 12, fontWeight: "normal" }}
        >
          {props.label}
        </InputLabel>
        <Select
          labelId="simple-select-label"
          {...props}
          inputProps={{ ...field }}
          value={props.value}
          label={props.label}
          error={!!(meta.touched && meta.error)}
          MenuProps={menuProps}
          classes={{ root: classes.select }}
          input={<BootstrapInput />}
        >
          <MenuItem value={""}>Select</MenuItem>
          {
            options.map((option) => (
              <MenuItem key={option._id} value={option._id}>
                {option.name}
              </MenuItem>
            ))
          }
        </Select>
        <FormHelperText error>{meta.touched && meta.error}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default Select;
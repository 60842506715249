import React from "react";
import dayjs, { Dayjs } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import RightArrowIcon from "@mui/icons-material/ArrowForward";

import CustomButton from "components/CustomButtons";
import TextField from "components/TextField";
import FieldWrapper from "./FieldWrapper";
import OptionChips from "./OptionChips";
import DatePickerField from "./DatePickerField";
import { useLoading, useSnackbar } from "contexts";
import { useDraft } from "hooks";
import { saveTemplateFromDraft, saveTemplateWithQuestions } from "services";
import { TemplateTypes } from "./constants";
import useEditorStore from "./editorStore";
import BatchSelection from "./BatchSelection";
import AllowedAttempts from "./AllowedAttempts";
import ProctorSettings from "./ProctorSettings";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Session, copyToClipboard } from "utils";
import { IconButton } from "@mui/material";

dayjs.extend(advancedFormat);

const ValidationSchema = Yup.object({
  name: Yup.string().trim().required("Give a name to your template"),
  type: Yup.string().required("Select type of template"),
  batch: Yup.string().required("Select a batch"),
  publishOn: Yup.date().required(),
  expiresOn: Yup.date().required(),
  maxAllowedAttempts: Yup.number()
    .when("type", {
      is: (type) => type === "practice",
      then: () => Yup.number().max(10, "Cannot be more than 10"),
      otherwise: () => Yup.number(),
    })
    .required("Required")
    .notOneOf([0], "Cannot be zero"),
  proctorSettings: Yup.object({
    copyPasteProctoring: Yup.bool().optional().default(true),
    generalProctoring: Yup.bool().optional().default(false),
    imageCaptureProctoring: Yup.bool().optional().default(false),
  }).optional(),
});

const DefaultValues = {
  name: "",
  type: "practice",
  batch: "",
  publishOn: "",
  expiresOn: "",
  maxAllowedAttempts: -1,
  proctorSettings: {
    copyPasteProctoring: true,
    generalProctoring: true,
    imageCaptureProctoring: false,
  },
};

const ActionStyle = { marginRight: 8 };

function DetailsForm({ ...props }) {
  const loading = useLoading();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const docId = useEditorStore((state) => state.templateId);
  const draftId = useEditorStore((state) => state.draftId);
  const questions = useEditorStore((state) => state.questions);
  const filters = useEditorStore((state) => state.filters);
  const reset = useEditorStore((state) => state.reset);
  const setDraftId = useEditorStore((state) => state.setDraftId);

  const { load, draftSaver, data } = useDraft({
    draftId,
    onNewDraft: setDraftId,
    name: "TEMPLATE_DETAILS",
    docId,
    fields: new Set([
      "_id",
      "name",
      "type",
      "expiresOn",
      "publishOn",
      "batch",
      "maxAllowedAttempts",
      "proctorSettings",
    ]),
  });

  const {
    handleSubmit,
    values,
    handleChange,
    setValues,
    errors,
    setFieldValue,
    handleBlur,
    touched,
  } = useFormik({
    initialValues: DefaultValues,
    validationSchema: ValidationSchema,
    onSubmit: async () => {
      try {
        loading.show();

        if (docId) {
          const newQuestions = questions.map((q) =>
            q._id ? q : { ...q, user: Session.userId, interviewTemplate: docId }
          );
          await saveTemplateWithQuestions(
            docId,
            { ...filters, ...values, lastModifiedAt: dayjs().toISOString() },
            newQuestions
          );
        } else {
          await draftSaver(values)();
          await saveTemplateFromDraft(draftId, docId);
        }

        reset();

        navigate(`/assessments`);

        snackbar.showSnackbar("Saved the template succesfully!!");
      } catch (error) {
        snackbar.showSnackbar(
          error.message || "Uh Oh! Unable to save your changes",
          "error"
        );
      } finally {
        loading.hide();
      }
    },
  });

  React.useEffect(() => load(draftId, docId), [docId, draftId]);

  React.useEffect(() => {
    if (data && Object.keys(data).length > 0) setValues(data, true);
  }, [data, setValues]);

  React.useEffect(() => {
    if (values.type === "assignment") {
      handleChange({ target: { name: "maxAllowedAttempts", value: 1 } });
    }

    if (values.type === "practice" && values.maxAllowedAttempts === 1) {
      handleChange({ target: { name: "maxAllowedAttempts", value: -1 } });
    }
    draftSaver(values);
  }, [values]);

  const handleDateChange = React.useCallback(
    (name, date) => setFieldValue(name, date),
    [setFieldValue]
  );

  return (
    <Box {...props} overflow="hidden" pl={2} mt={5}>

      <Typography
        variant="h4-semiBold"
        pl={2}
        pb={1}
      >
        Save your template
      </Typography>

      {
        (docId) && <Box mb={2} pl={2} display='flex' alignItems='center'>
          <Typography fontSize={16} fontWeight={600} >
            Assessment Id : <span style={{ color: '#02569D' }}>{docId}</span>
          </Typography>
          <IconButton onClick={() => {
            copyToClipboard(docId);
            snackbar.success('Copied');
          }}>
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Box>
      }

      <form >
        <Grid container pt={0} mt={4}>
          <FieldWrapper
            title="Assessment name*"
            description="Give a name to this template, Students will see this as template heading"
          >
            <TextField
              readOnly={docId}
              name="name"
              placeholder="Enter name..."
              style={{ maxWidth: 470 }}
              value={values?.name}
              onBlur={handleBlur}
              onChange={handleChange}
              error={touched.name && errors.name}
              helperText={touched.name && errors.name}
            />
          </FieldWrapper>
          <FieldWrapper
            title="Assessment Type*"
            description="Select the type of template"
          >
            <OptionChips
              options={TemplateTypes}
              name="type"
              onChange={handleChange}
              defaultValue={values.type}
            />
          </FieldWrapper>
          <BatchSelection
            readOnly={docId}
            onChange={handleChange}
            value={values?.batch || ""}
            error={touched.batch && errors.batch}
            helperText={touched.batch && errors.batch}
          />
          <AllowedAttempts
            disabled={values?.type === "assignment"}
            onChange={handleChange}
            value={values?.maxAllowedAttempts}
            error={touched.maxAllowedAttempts && errors.maxAllowedAttempts}
            helperText={touched.maxAllowedAttempts && errors.maxAllowedAttempts}
          />
        </Grid>

        <Grid container pt={0}>
          <DatePickerField
            description={
              <>
                You can choose to publish the assessment <b>Now</b> or schedule
                it for a later date.
              </>
            }
            name="publishOn"
            label="Publish On*"
            value={values.publishOn}
            onChange={handleDateChange}
            defaultValue={dayjs().toISOString()}
          />
          <DatePickerField
            description={
              <>
                Set an expiry date for the assessment, or choose <b>Never</b> to
                keep it available indefinitely.
              </>
            }
            name="expiresOn"
            label="Expires On*"
            value={values.expiresOn}
            onChange={handleDateChange}
            disabled={!!!values.publishOn}
            minDate={values?.publishOn ? values.publishOn : undefined}
            defaultValue={dayjs().set("year", 9999).endOf("year").toISOString()}
          />
        </Grid>

        <Box py={1}>
          <ProctorSettings
            values={values?.proctorSettings || {}}
            onChange={handleChange}
          />
        </Box>
        <Box p={2} ml="auto" width="fit-content">
          <CustomButton
            variant="contained"
            onClick={handleSubmit}
            style={ActionStyle}
            endIcon={<RightArrowIcon />}
          >
            Save & Publish Assessment
          </CustomButton>
        </Box>
      </form>
    </Box>
  );
}

export default DetailsForm;

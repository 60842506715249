import React from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";

import VerticalProgressBar from "components/BatchAnalytics/VerticalProgressBar";
import DoughnutGraph from "components/Home/DoughnutGraph";
import { getTemplateScoreDistribution } from "services";
import ShadowBox from "components/ShadowBox";

const useStyles = makeStyles(theme=>({
  rangeBox: {
    display: "flex",
    mt:'15px', 
    justifyContent:'center',
    flexDirection:'column',
    px:'50px',
    gap:'10px'
  },
  avgBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '8px 12px',
    border: '1px solid',
    borderRadius: theme.spacing(1),
    borderColor: theme.palette.neutral['clr-200'],
    backgroundColor: theme.palette.neutral['clr-50']
  },
  leftSec: {
    width: '50%', 
    display: 'flex', 
    justifyContent: 'center', flexDirection: 'column',
  },
  legendsBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: 'space-between',
  },
  batchAvgRoot: {
    display: 'flex', justifyContent: 'center', 
    marginTop: '20px',
  }
}));

const AverageScoreLegends = [
  { range: "0-30", color: "#E2483C" },
  { range: "31-60", color: "#F5B847" },
  { range: "61-100", color: "#009970" },
];

function TemplateStats({ template, forPersonalizedTemplate }) {
  const classes = useStyles();
  const { id } = useParams();

  const [scoreDistribution, setScoreDistribution] = React.useState({
    "0-30": 0,
    "31-60": 0,
    "61-100": 0,
    averageScore: 0,
  });

  const [attemptRate, setAttemptRate] = React.useState({
    rate: 0,
    attempted: 0,
    unAttempted: 0,
  });

  React.useEffect(() => {
    const attempted = template?.attemptedBy?.length || 0;
    const total = template?.assignedTo?.length || 0;
    const unAttempted = total - attempted;

    setAttemptRate({
      attempted,
      unAttempted,
      rate: Math.round((attempted / total) * 100),
    });
  }, [template]);

  const totalStudents = React.useMemo(()=>{
    let total = 0;
    AverageScoreLegends.map(data=>
      total += scoreDistribution[data.range]
    )

    return total;
  },[scoreDistribution, AverageScoreLegends])

  React.useEffect(() => {
    getTemplateScoreDistribution(
        forPersonalizedTemplate ? {personalizedTemplateDraftId: id} : {templateId: id}
      ).then((scoreDistribution) => setScoreDistribution(scoreDistribution))
      .catch(console.error);
      
  }, [id]);

  return (
    <ShadowBox width="65%" display="flex" justifyContent="space-evenly">
      <Box className={classes.leftSec}>
        <DoughnutGraph
          rootStyle={{ padding: 0 }}
          color="#0263FF"
          score={attemptRate.rate || 0}
          data={[attemptRate.attempted, attemptRate.unAttempted]}
          labels={["No. of students", "No. of students"]}
          title="Attempt Rate"
          muiltiProgress={true}
          isPercentage
          bgColors={['#009970', '#F5B847']}
        />
        <Box className={classes.rangeBox} my={4}>
          <Typography variant="body01-bold" textAlign='center'>
            Attempt rate of Students
          </Typography>
          {[
            {color: '#009970', range: attemptRate.attempted , tag: 'Students taken the test'},
            {color: '#F5B847', range: attemptRate.unAttempted, tag: 'Students not taken the test'}
          ].map((x,index) => (
            <Box className={classes.legendsBox}>
                <Box display="flex" alignItems="center" gap='10px'>
                  <Box width={20} bgcolor={x.color} p={0.5} borderRadius={8} />
                  <Typography variant='body02-bold'>
                    {x.tag}
                  </Typography>
                </Box>
              <Typography variant='body02-bold'>
                {x.range}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Box width='50%' display='flex' justifyContent='center'>
        <Box height='60%'>
          <VerticalProgressBar
            averageScoreStats={scoreDistribution || {}}
            averageScoreLegends={AverageScoreLegends || []}
            xAsix={'Score Range'}
            yAsix={'No. of Students'}
            totalStudents={totalStudents}
          />
          <Box className={classes.batchAvgRoot}>
            <Box className={classes.avgBox}>
              <Typography variant="h5-bold">
                {scoreDistribution.averageScore || 0}
              </Typography>
              <Typography variant="h6-medium">
                Batch Average
              </Typography>
              <Typography variant='body01-semiBold' color='neutral.clr-600'>
                Score consisting of {totalStudents} students
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </ShadowBox>
  );
}

export default TemplateStats;

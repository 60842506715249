import React from 'react';
import {
    Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Toggle from './Toggle';
import { BlueSwitch } from 'components/Switch';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

function Legends({ legends = [], states, onChange, ...props }) {

    return (
        <>
            <Box display='flex' width={'fit-content'} gap={2} {...props}>
                {legends.map((legend, index) => {
                    const Switch = legend.Switch || BlueSwitch;

                    return (
                        <FormControlLabel
                            key={index}
                            control={
                                <Switch
                                    name={legend.id}
                                    checked={states?.[legend.id]}
                                    onChange={onChange}
                                />}
                            label={legend.label}
                        />
                    )
                })}
            </Box>
        </>
    );
}

const options = {
    responsive: true,
    maintainAspectRatio: true,
    interaction: { mode: 'index', intersect: false },
    plugins: {
        legend: { position: 'top', display: false },
        title: { display: false, text: 'Performance', },
    },
    scales: { yAxis: { min: 0, max: 100, } }
};

const sx = {
    container: {
        width: "100%",
        background: '#FFFFFF',
        border: "1px solid lightgrey",
        borderRadius: 2,
        padding: 4,
        textAlign: 'center',
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: "row-reverse",
        mb: 2,
    },
};

export default function BarGraph({
    xLabel,
    addToggle,
    aspectRatio,
    heading,
    toggleOptions,
    graphOptions = options,
    labels = [],
    datasets = [],
    legends = [],
    onToggleChange = () => { },
    ...props
}) {
    const [legendStates, setLegendStates] = React.useState({});

    const _datasets = React.useMemo(() => {
        return datasets.filter(dataset => legendStates[dataset.id])
    }, [datasets, legendStates]);

    const handleLegendChange = (event) => {
        setLegendStates((ls) => ({
            ...ls, [event.target.name]: event.target.checked
        }));
    };

    React.useEffect(() => {
        if (legends.length) {
            setLegendStates(legends.reduce((acc, legend) => {
                acc[legend.id] = true;
                return acc;
            }, {}))
        }
    }, [legends]);

    return (
        <Box sx={sx.container} {...props}>
            <Box sx={sx.header}>
                {addToggle && (
                    <Toggle
                        options={toggleOptions}
                        br={30}
                        width={170}
                        height={28}
                        onChange={onToggleChange}
                    />
                )}

                <Legends
                    legends={legends}
                    states={legendStates}
                    onChange={handleLegendChange}
                />

                {heading && <Typography fontSize={15} color={'#666666'} >
                                {heading}
                            </Typography>
                    }
            </Box>
            <div>
                <Box display="flex" alignItems="center">
                    <Typography
                        variant='body02-bold' 
                        color='neutral.clr-600'
                        component="div"
                        style={{ transform: "rotate(-90deg)" }}
                    >
                        Score
                    </Typography>
                    <div style={{ flexGrow: 1 }}>
                        <Bar
                            options={{ ...graphOptions, aspectRatio }}
                            data={{ labels, datasets: _datasets }}
                        />
                    </div>
                </Box>
                <Typography variant='body02-bold' mt={1} color='neutral.clr-600' >
                    {labels.length > 0 && xLabel}
                </Typography>
            </div>
        </Box>
    );
}
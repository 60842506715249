import React from 'react';
import { Box } from '@mui/material';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import makeStyles from "@mui/styles/makeStyles";

import { getBestPerformersOfBatch } from 'services';
import { Link, useParams } from 'react-router-dom';
import ShadowBox from 'components/ShadowBox';
import { SimpleSelect } from 'components/CustomSelectFields';
import { IconButton } from 'components/CustomButtons';
import { useTheme } from '@mui/styles';

const useStyles = makeStyles(theme=>({
  container: {
    border: "1px solid lightgray", borderRadius: '4px', 
    margin: theme.spacing(2.5,0)
  },
  leaderBoardContent: {
    display: 'flex', justifyContent: 'space-between', 
    alignItems: 'center'
  },
  tableHeader: {
    ...theme.typography['body01-bold'],
    height: '40px'
  },
  tableRow: {
    ...theme.typography['body01-medium']
  }
}));

const Categories = {
  BEST_PERFORMER : 1, TOP_PERFORMER: 2
}

const LeaderboardOptions = [
  {_id: 1, name: 'Best Scores'},
  {_id: 2, name: 'Most Dedicated'}
]

const Rows = ({row, LeaderboardCategory}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <TableRow
      key={row.name}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="td" scope="row" className={classes.tableRow}>
        {row.name}
      </TableCell>
      <TableCell component="td" scope="row" align='center'>
        {
          LeaderboardCategory === Categories.BEST_PERFORMER 
          ? Math.round(row.average) : row.totalAttempts
        }
      </TableCell>
      <TableCell component="td" scope="row" align="center">
        <Link to={`/students/${row._id}`}>
          <IconButton 
            variant='contained'
            style={{
                      border : `1px solid ${theme.palette.primary.main}`,
                      padding : theme.spacing(1),
                      marginLeft : theme.spacing(9)
                  }}
            >
              <img 
                src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/PerformanceIcon.svg' 
                alt='performance' 
              />
          </IconButton>
        </Link>
      </TableCell>
    </TableRow>
  )
}

function BestPerformers({ batch, forPersonalizedTemplate = false }) {
  const { id } = useParams();
  const classes = useStyles();

  const [bestPerformers, setBestPerformers] = React.useState([]);
  const [topPerformers, setTopPerformers] = React.useState([]);
  const [LeaderboardCategory, setLeaderboardCategory] = React.useState(Categories.BEST_PERFORMER);

  React.useEffect(() => {
    if (id && batch) {
      getBestPerformersOfBatch(batch, 
        forPersonalizedTemplate ? {personalizedTemplateDraftId: id} : {templateId: id}
      ).then(users => {
          setBestPerformers(users?.bestPerformers);
          setTopPerformers(users?.topPerformers);
        })
        .catch(console.error);
    }
  }, [id, batch]);

  return (
    <ShadowBox width="35%">
      <Typography variant="h4-medium">Leaderboard</Typography>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box display='flex' gap='5px' alignItems='center' >
          <img src="https://assets.languify.in/images/badgegold.svg" 
            alt="bronze" style={{width: "15px", height: "15px"}}
          />
          <Typography variant='body01-medium'>
            {LeaderboardOptions[LeaderboardCategory-1].name}
          </Typography>
        </Box>
        <Box>
        <SimpleSelect
          options={LeaderboardOptions}
          value={LeaderboardCategory}
          onSelect={(e)=>{setLeaderboardCategory(e.target.value)}}
          sx={{width: '150px'}}
        />
        </Box>
      </Box>
      <TableContainer sx={{ border: "1px solid lightgray", borderRadius: 1, mt: '10px' }}>
        <Table size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: '#F0F5FA' }}>
              <TableCell className={classes.tableHeader}>Student Name</TableCell>
              <TableCell align='center' className={classes.tableHeader}>
                { LeaderboardCategory === 1? 'Scores' : 'Attempts'}
              </TableCell>
              <TableCell align="center" className={classes.tableHeader}>Analytics</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              LeaderboardCategory === Categories.BEST_PERFORMER 
              ? 
              bestPerformers?.length ? 
                bestPerformers.map((row) => (
                <Rows row={row} LeaderboardCategory={LeaderboardCategory} />
                )) : (
                  <TableRow>
                    <TableCell component="td" scope="row" className={classes.tableRow}>
                      No attempts yet!
                    </TableCell>
                  </TableRow>
                )
              : 
              topPerformers?.length ? 
                topPerformers.map((row) => (
                <Rows row={row} LeaderboardCategory={LeaderboardCategory} />
              )) : (
                <TableRow>
                  <TableCell component="td" scope="row" className={classes.tableRow}>
                    No attempts yet!
                  </TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </ShadowBox>
  );
}

export default BestPerformers;
import * as React from 'react';
import MuiAccordion from "@mui/material/Accordion";
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { AvailableProctorSettings } from './constants';
import { styled } from '@mui/material/styles';
import theme from 'theme';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: "transparent",
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

function Setting({ id, name, label, values, onChange, description, Icon }) {
  const handleChange = (event) => {
    onChange({ target: { value: event.target.checked, name } });
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" p={1}>
      <Box>
        <Typography
          variant="body01-bold"
          display="flex"
          alignItems="center"
          columnGap={2}
        >
          <Icon fontSize="small" />
          {label}
        </Typography>
        <Typography variant="body02-semiBold" color={'neutral.clr-600'}>
          {description}
        </Typography>
      </Box>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant='body01-medium'>Off</Typography>
        <Switch
          checked={values?.[id]}
          onChange={handleChange}
          color="secondary"
        />
        <Typography variant='body01-medium'>On</Typography>
      </Stack>
    </Box>
  );
}

function ProctorSettings(props) {

  return (
    <Accordion sx={{
      mb: 4, mt: 6, borderRadius: '4px !important',
      border: '2px solid', borderColor: '#D3D8DE', padding: '12px 8px'
    }} defaultExpanded={props.defaultExpanded ?? true} >
    {!props.boxShadow &&   
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='h6-medium' >Proctoring Settings</Typography>
      </AccordionSummary>}
      <AccordionDetails sx={{padding:theme.spacing(0)}}>
        {AvailableProctorSettings.map((setting, i) => (
          <Setting key={i} {...setting} {...props} />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

export default ProctorSettings;
import React from 'react';
import Box from '@mui/material/Box';
import MuiTab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import makeStyles from "@mui/styles/makeStyles";

import Questions from './Questions';
import ImportQuestions from './ImportQuestions';
import Suggestions from './Suggestions';
import { Session } from 'utils';

const useStyles = makeStyles(theme=>({
  tab: {
    ...theme.typography['body01-bold'],
  }
}))

function Tab(props) {
    return (
        <MuiTab
            style={{ textTransform: "none"}}
            {...props}
        />
    );
}

function QuestionSection(props) {
  const classes = useStyles();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', overflow: "hidden" }} {...props}>
            <Typography
                variant="h4-semiBold"
                pt={0}
                pl={2}
                pb={0}
                position={'sticky'}
                top={'0px'}
                zIndex={2}
            >
                {value ? "Browse Questions" : "Add Questions"}
            </Typography>
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: '#00000017',
                    position: 'sticky',
                    top: '32px',
                    zIndex: '2',
                    background: 'white'
                }}
            >
                <ImportQuestions />

                <Tabs value={value} onChange={handleChange}>
                    <Tab value={0} label="Your Questions" className={classes.tab}/>
                    <Tab value={1} label="Add from Languify Library" className={classes.tab}/>
                    <Tab
                        value={2}
                        label={`Add from ${Session.getUser().name} Library`}
                        className={classes.tab}
                    />
                </Tabs>
            </Box>
            <Questions show={value === 0} editMode={props?.editMode || false}/>
            <Suggestions show={value === 1} isLanguify={true} />
            <Suggestions show={value === 2} isLanguify={false} />
        </Box>
    );
}

export default QuestionSection;
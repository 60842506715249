import Box from '@mui/material/Box';
import React, { useState, useMemo } from 'react';
import shallow from 'zustand/shallow';
import Select from '@mui/material/Select';
import { useParams } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import { DemoApplications, templateTypes } from 'components/PerformanceReview/constants';
import { Session, getAverageScore, getPercentageChange, round } from 'utils';
import InfoIconWithTooltip from 'components/InfoIconWithTooltip';
import { PerformanceGraph } from './PerformanceGraph';
import CircleProgressbar from './CircleProgressbar';
import { getCommentAndColor } from './helpers';
import ShadowBox from 'components/ShadowBox';
import AttemptGraph from './AttemptGraph';
import useStore from './store';

const sx = {
    root: {
        mb: 5,
        p: 0,
        display: "flex",
        gap: "5px",
        background: "white",
    },
    compliment: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 18,
        lineHeight: "26px",
        color: "#000000",
    },
    percentile: {
        fontFamily: "Montserrat",
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: 18,
        lineHeight: "22px",
        color: "#000000",
    },
    overall: {
        padding: "20px 0px",
        minWidth: 400,
        boxShadow: "0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)",
        p: 2,
    },
    attemptMessage: {
        display: 'flex',
        gap: '10px',
        fontWeight: 500,
        fontSize: '12px',
        alignItems: 'center'
    },
    links: {
        color: '#02569D',
        fontWeight: 600,
        fontSize: '12px',
        cursor: "pointer"
    },
    assignmentBot: {
        width: '80%',
        height: '100%',
        position: 'absolute',
        bottom: '-33%',
        right: '10%',
        zIndex: '900',
    },
    attemptErrorBot: {
        width: '120px',
        height: '120px',
        position: 'absolute',
        bottom: '0px',
        right: '0px',
        zIndex: '900',
    },
    percentage: {
        display: "flex",
        alignItems: "center",
        fontWeight: 600,
        padding: "2px",
        fontSize: "12px",
    },
};

function GraphHeader({
    attempt,
    setAttempt,
    parameter,
    setParameter,
    numberOfAttempts = 1,
    title = 'Assessment',
    allAttempts = []
}) {
    const setAttemptNumber = useStore(state => state.setAttemptNumber, shallow);
    const attemptId = useStore(state => state.attemptId);

    const { aid } = useParams();
    const [changeAttempt, setChangeAttempt] = useState(0);

    React.useEffect(() => {
        if(allAttempts.length > 0){
            setChangeAttempt(allAttempts.findIndex(obj => obj._id === aid));
        }
    }, [allAttempts])
    
    React.useEffect(() => {
        allAttempts.forEach((data, index) => {
            if (data._id === attemptId) {
                setAttemptNumber(index + 1)
                setChangeAttempt(index);
            }
        })
    }, [attemptId, allAttempts])

    return (
        <Box display="flex" pl={2} alignItems='center' justifyContent="space-between">
            <Typography fontWeight={600} fontSize={20}>
                {title}
            </Typography>
            <Box display="flex" alignItems='center' flexWrap={'wrap'}>
                <FormControl
                    sx={{ m: 1, width: 200 }}
                    size="small"
                    fullWidth
                >
                    <InputLabel id="attempt-label">
                        Choose Attempt
                    </InputLabel>
                    <Select
                        input={(
                            <OutlinedInput
                                label="Choose attempt"
                                size="small"
                            />
                        )}
                        id="attempt"
                        labelId="attempt-label"
                        value={attempt}
                        onChange={e => setAttempt(e.target.value)}
                    >
                        {new Array(numberOfAttempts).fill('x').map((v, i) => (
                            <MenuItem key={i} value={i}>
                                Attempt {i + 1}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, width: 200 }} size="small" fullWidth>
                    <InputLabel id="parameter-label">
                        Choose parameter
                    </InputLabel>
                    <Select
                        input={<OutlinedInput label="Choose parameter" />}
                        id="parameter"
                        labelId="parameter-label"
                        value={parameter}
                        onChange={e => setParameter(e.target.value)}
                    >
                        {['Overall', 'Speech', 'Content'].map((value, i) => (
                            <MenuItem
                                key={i}
                                value={(value === 'Speech' ? 'Communication' : value)}
                            >
                                {value}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    );
}

export function AvgScoreProgressBarWithMessage({
    gap = 3,
    barSize = 100,
    score = 0,
    fontSize,
    headingFontSize,
    headingColor,
    avgHeading,
    showComparision=false,
    changePercentage= 0,
    tooltip, ...props
}) {

    return (
        <Box display="flex" gap={gap} alignItems={"center"}>
            <CircleProgressbar size={barSize} value={score} />
            <Box>
                <Typography
                    color={headingColor || "black"}
                    fontSize={headingFontSize || 26} fontWeight={600}>
                    {avgHeading}&nbsp;
                    {
                        tooltip &&
                        <InfoIconWithTooltip
                            placement='right'
                            title={tooltip}
                        />
                    }
                </Typography>
                <Typography fontSize={fontSize || 14} color="#424242" fontWeight="500">
                    {props.avgDescription}
                </Typography>
                {
                    showComparision? 
                    <Box
                        sx={sx.percentage}
                        color={changePercentage >= 0 ? "#00C7BE" : "#FF2D55"}
                    >
                        {changePercentage >= 0 ? (
                        <ArrowUpwardIcon sx={{ fontSize: 15 }} />
                        ) : (
                        <ArrowDownwardIcon sx={{ fontSize: 15 }} />
                        )}
                        {changePercentage  < 0 ? -round(changePercentage) : 
                                                round(changePercentage)
                                            }% 
                        &nbsp; 
                        <InfoIconWithTooltip
                            placement='right'
                            title={ "Score change over the previous attempts' average" }
                        />
                    </Box>
                    : null
                }
            </Box>
        </Box>
    )
}

function MultipleAttemptsPerformance({ allAttempts, template }) {
    const attemptId = useStore(state => state.attemptId);
    const attemptNumber = useStore(state => state.attemptNumber);
    allAttempts = allAttempts?.map((data,index)=>{
        return {...data,attemptNo:index+1}
    })
    
    const recentAttempts = useMemo(() => {
        return (
            allAttempts?.length < 5 ? allAttempts : 
                ( attemptNumber === allAttempts.length ? allAttempts.slice(-4) : 
                    allAttempts.slice(
                        Math.max(0,attemptNumber-4), 
                        attemptNumber <= 4 ? 4 : attemptNumber
                    )
                )
        )
    }, [allAttempts,attemptNumber]);

    return (
        <>
            <Box sx={{
                width: "100%",
                border: "2px solid #327AB7",
                borderRadius: 1,
                height: "100%",
                padding: "8px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                overflow: "hidden"
            }}
                id="your-progress"
            >
                <Typography fontSize={"15px"} fontWeight={600}>
                    Progress card
                </Typography>

                <Box backgroundColor="rgba(45, 171, 255, 0.24)" p="5px" borderRadius="5px">
                  {
                    recentAttempts.length > 1 ?
                    <Typography fontSize="10px" color="#5A5A5A" fontWeight="500"
                        display="flex" alignItems="center" justifyContent="center"
                    >
                        Progress card displays progress over last attempts. 
                        "*Shows recent 4 attempts score" 
                    </Typography>
                    : 
                    <Box display="flex" flexDirection='column' gap='5px'>
                      <Typography fontSize='14px' color='#02569D'>
                        Only 1 attempt taken!!
                      </Typography>
                      <Typography fontSize="10px" color="#5A5A5A" fontWeight="500">
                        User have not taken enough attempt to track their progress.
                      </Typography>
                      <Typography fontSize="10px" color="#5A5A5A" fontWeight="500">
                        The progress card tracks a user's performance growth over time, displaying the results of their latest four attempts for tracking, practice, and improvement.
                      </Typography>
                    </Box>
                  }
                </Box>
                <AttemptGraph 
                    recentAttempts={recentAttempts}
                    currentAttempt={attemptId}
                />
            </Box>
        </>
    )
}


function OverallPerformance(props) {
    const setCurrentQuestion = useStore(state => state.setCurrentQuestion, shallow);
    const questions = useStore(state => state.questions, shallow);
    const score = useStore(state => state.score, shallow);
    const content = useStore(state => state.content, shallow);
    const speech = useStore(state => state.speech, shallow);
    const attemptNumber = useStore(state => state.attemptNumber, shallow);
    const demoApplication = useStore(state => state.demoApplication, shallow);
    const allAttempts = useStore(state => state.allAttempts, shallow);
    const template = props.template || {};
    
    const [contentChange, setContentChange] = React.useState(0);
    const [speechChange, setSpeechChange] = React.useState( 0);

    React.useEffect(()=>{
        if(attemptNumber > 1 && demoApplication === DemoApplications.teachAndTrain){
            const previousAttempt = allAttempts[attemptNumber - 2].attemptSummary;
            setContentChange(getPercentageChange(content, previousAttempt?.content));
            setSpeechChange(getPercentageChange(speech, previousAttempt?.speech));
        }
        else if(attemptNumber > 1 && demoApplication !== DemoApplications.teachAndTrain){
            let previousSpeechTotal =0;
            let previousContentTotal =0;
            let count =0;
            allAttempts.forEach((attempt, index)=>{
                if(index < attemptNumber-1){
                    previousSpeechTotal += attempt.communication;
                    previousContentTotal += attempt.content;
                    count += 1;
                }
            })
            previousSpeechTotal /= count;
            previousContentTotal /= count;
            setContentChange( getPercentageChange(content, previousContentTotal) );
            setSpeechChange( getPercentageChange(speech, previousSpeechTotal) );
        }
    },[attemptNumber, allAttempts, content, speech])

    const { communication: communicationWeightage, content: contentWeightage} = 
      {
        communication: Session?.user?.metadata?.performanceWeightage?.communication || 50,
        content: Session?.user?.metadata?.performanceWeightage?.content || 50,
      }

    return (
        <>
            <Box display={"flex"} gap={1} alignItems={"center"} id="overall-score-container">
                <Box width={"75%"} display="flex" id="overall-score">
                    <AvgScoreProgressBarWithMessage
                        score={score}
                        headingColor="#004D98"
                        avgHeading={'Overall Score'}
                        avgDescription={getCommentAndColor(score).feedback}
                        tooltip={<>
                            Assessment score depends on a number of factors:<br />
                            1. Speech Average<br />
                            2. Content Average<br />
                            3. Total number of questions attempted
                        </>}
                    />
                </Box>
                <Box sx={{ display: "flex", gap: "1", justifyContent: "space-evenly" }} mt={2}>
                    <Box border={"1px solid black"} borderRadius={"5px"}>
                        <Typography
                            fontSize={"12px"}
                            fontWeight={"600"}
                            p={"8px"}
                            backgroundColor={"#2DABFF3D"}
                        >
                            Overall score is a total of your {communicationWeightage}% speech
                            and {contentWeightage}% content
                        </Typography>
                        <Box display={"flex"} p={"8px"}>
                            <AvgScoreProgressBarWithMessage
                                score={content}
                                avgHeading={"Content Score"}
                                avgDescription={"Overall Content Score of this attempt"}
                                barSize={45}
                                fontSize={10}
                                headingFontSize={12}
                                gap={1}
                                changePercentage={contentChange}
                                showComparision={
                                        (
                                            (template?.type === templateTypes.PRACTICE ||
                                                demoApplication === DemoApplications.teachAndTrain
                                            ) && 
                                            attemptNumber > 1
                                        )? true : false
                                    }
                            />
                            <AvgScoreProgressBarWithMessage
                                score={speech}
                                avgHeading={"Speech Score"}
                                avgDescription={"Overall Speech Score of this attempt"}
                                barSize={45}
                                fontSize={10}
                                headingFontSize={12}
                                gap={1}
                                changePercentage={speechChange}
                                showComparision={
                                        (
                                            (template?.type === templateTypes.PRACTICE ||
                                                demoApplication === DemoApplications.teachAndTrain
                                            ) && 
                                            attemptNumber > 1
                                        )?  true : false
                                    }
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

            <ShadowBox sx={sx.root}>
                <Box width={"70%"} p={1}>
                    <GraphHeader allAttempts={allAttempts} {...props} />
                    <PerformanceGraph
                        hideHeader
                        xLabel="Questions"
                        onClick={setCurrentQuestion}
                        labelPrefix="Q"
                        data={questions?.map((question) => {
                            const communication = question?.communication?.ratings?.OVERALL || 0;
                            const content = question?.content?.ratings?.OVERALL || 0;
                            return {
                                communication,
                                content,
                                overall: getAverageScore(communication, content),
                                _id: question._id,
                            };
                        })}
                        tooltipCallbacks={{
                            title: ([item]) =>
                                `Q${item.dataIndex + 1}. ${
                                    questions?.[item.dataIndex].question.length <= 35? 
                                    questions?.[item.dataIndex].question
                                    :
                                    questions?.[item.dataIndex].question.slice(0,35) + "..." 
                                    }`
                        }}
                        {...props}
                    />
                </Box>
                <Box width={'30%'} position={'relative'} p={1} id="your-progress-container">
                    {
                        template.type === templateTypes.ASSIGNMENT ?
                            <box style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <img
                                    src={"https://assets.languify.in/images/assign-bot.png"}
                                    alt='bot'
                                    style={sx.assignmentBot}
                                />
                            </box>
                            :
                            <MultipleAttemptsPerformance 
                                allAttempts={allAttempts} 
                                template={template}
                            />
                    }
                </Box>
            </ShadowBox >
        </>
    );
}

export default OverallPerformance;
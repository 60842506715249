import React, { useEffect } from 'react';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Session from 'utils/Session';
import Typography from '@mui/material/Typography';
import ChangePassword from 'forms/ChangePassword';
import ResetLink from 'forms/ResetLink';
import SignInForm from 'forms/SignIn';

export default function Auth() {
    const navigate = useNavigate();
    useEffect(() => {
        if (Session.isLoggedIn()) navigate('/users');
    }, []);

    return (
        <Routes>
            <Route path='/' element={<AuthLayout />} >
                <Route
                    path="login"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <SignInForm />
                        </React.Suspense>
                    }
                />
                <Route
                    path="reset-link"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <ResetLink />
                        </React.Suspense>
                    }
                />
                <Route
                    path="reset-password"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <ChangePassword />
                        </React.Suspense>
                    }
                />
            </Route>
        </Routes>
    );
}


function AuthLayout() {

    return (
        <Box display='flex' height='100vh'>
            <Box
                display='flex' width='50%' height='100%'
                flexDirection='column' backgroundColor='#ffffff' alignItems='center'
            >
                <br /><br /><br /><br /><br />
                <img
                    src="https://assets.languify.in/images/lan-logo.png"
                    alt='Languify'
                    height="50"
                />
                <Typography
                    color='primary' variant='h3-medium'
                >
                    Inprep Admin
                </Typography> <br /><br /> <br /> <br />

                <Outlet />

            </Box>
            <Box
                display='flex' width='50%' height='100%' backgroundColor='#004ea0'
                justifyContent='flex-end' alignItems='center'
            >
                <img
                    src="https://assets.languify.in/images/client-admin-01.png"
                    alt="Admin portal"
                    width='80%'
                />
            </Box>
        </Box>
    );
}
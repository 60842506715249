
import React from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

import ArrowBack from '@mui/icons-material/ArrowBack';
import makeStyles from '@mui/styles/makeStyles';

import CustomButton from './CustomButton';

const useStyles = makeStyles({
  button: {
    height: 56,
    margin: '0',
    border: 'none',
    borderRadius: '0px',
    padding: 0,
    minWidth: '25px',
    width: '25px',
    backgroundColor: 'inherit', 
    '&:hover': {
      backgroundColor: 'inherit',
    },
  }
});

function BackButton(props) {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const isHomePage = location.pathname === "/home";

  return isHomePage ? null : (
    <>
      <CustomButton
        variant="text"
        size="small"
        className={classes.button}
        startIcon={<ArrowBack style={{fontSize: '28px'}}/>}
        onClick={() => navigate(-1)}
      /> &nbsp;&nbsp;
    </>
  )
}

export default BackButton;
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import MUIChip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import ShadowBox from 'components/ShadowBox';
import { formatDate } from 'utils';

function Chip(props) {
  return (
    <MUIChip
      size="small"
      variant="outlined"
      color="primary"
      component="span"
      {...props}
    />
  );
}

const styles = {
  detail: { marginTop: 1, fontWeight: 600, fontSize: 14 },
  label: { fontStyle: "normal", color: "#111519", fontWeight: 500 },
  capitalizeText: { textTransform: 'capitalize' }
};

function Detail({ label, children, capitalize }) {
  return (
    <Typography
      variant='h6-medium'
      color='neutral.clr-600'
      style={capitalize ? styles.capitalizeText : {}}
    >
      <span style={styles.label}>{label}:&nbsp;</span>{children}
    </Typography>
  );
}

function TemplateInfo({ template }) {
  const averageTime = template?.metadata?.averageTime || 0

  const mins = Math.floor(averageTime / 60);
  const secs = averageTime % 60;

  let atText = 'NA';

  if (mins) atText = `${mins} min`;

  if (secs) atText = `${mins > 0 ? mins + ' min ' : ''}${secs} secs`

  return (
    <ShadowBox display="flex" alignItems="stretch" m={0}
      style={{paddingTop: '16px', paddingBottom: '16px' ,width : '100%'}}
    >
      <Box display='flex' flexDirection='column' gap='16px' width='100%'>
       <Box width='100%'>
        <Typography variant="h4-medium">
            {template?.name}&nbsp;
            <Chip
              label={template?.type === 'personalized' ? 'Mock Interview' : template?.type}
              style={{ textTransform: 'capitalize' }}
            />
          </Typography>
       </Box>
       <Box display='flex' justifyContent='space-between'>
        <Box display='flex' flexDirection='column' gap='8px'>
          <Detail label="Id" capitalize>
            <Typography variant='h6-medium' color='neutral.clr-600'>
              {template?._id?.toString()}
            </Typography>
          </Detail>
          <Detail label="Total Attempts" capitalize>
            <Typography variant='h6-medium' color='neutral.clr-600'>
              {template?.metadata?.usedBy?.toString() || template?.totalAttemptsCount}
            </Typography>
          </Detail>
          {
            template?.difficulty ?
              <Detail label="Difficulty" capitalize>
                <Typography variant='h6-medium' color='neutral.clr-600'>

                  {template?.difficulty}
                </Typography>
              </Detail>
          : null
          }
          <Detail label="Published On" >
            <Typography variant='h6-medium' color='neutral.clr-600'>

              {formatDate(
                template?.publishOn || template?.createdAt, 'Do MMM, YYYY (dddd)'
              )}
            </Typography>
          </Detail>
        </Box>
        <Box display='flex' flexDirection='column' gap='8px'>
          <Detail label="Batch">
            <Typography variant='h6-medium' color='neutral.clr-600'>
              {template?.batch?.name || 'N/A'}
            </Typography>
          </Detail>
          <Detail label="Domain">
            <Typography variant='h6-medium' color='neutral.clr-600'>
              {template?.domain?.name}
            </Typography>
          </Detail>
          <Detail label="Average Attempt Time">
            <Typography variant='h6-medium' color='neutral.clr-600'>

              {atText}
            </Typography>
          </Detail>

          <Detail label="Expires On">
            <Typography variant='h6-medium' color='neutral.clr-600'>
              {
                (dayjs(template?.expiresOn).year() === 9999) ?
                  'Never' :
                  formatDate(template?.expiresOn, "Do MMM YY, dddd")
              }
            </Typography>
          </Detail>
        </Box>
       </Box>
      </Box>

    </ShadowBox>
  );
}

export default TemplateInfo;
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ShadowBox from "./ShadowBox";
import BackButton from "./BackButton";

const useStyles = makeStyles(theme=>({
  screenHeadingRoot: {
    display: 'flex',
    alignItems: 'center',
    borderLeft: '4px solid #02569d',
    backgroundColor: 'white',
    height: '64px',
    borderRadius: '4px',
    marginBottom: theme.spacing(5),
    padding: theme.spacing(0,6)
  },
  contentTitle: {
    flexShrink: 0,
    minWidth: 200,
    alignSelf: "center",
  },
  contentActions: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap",
    alignItems: "center",
    paddingRight: 16,
  }
}));

function ScreenHeading({ id = '', title, children, hideBack = false }) {
  const classes = useStyles();

  return (
    <ShadowBox
      className={classes.screenHeadingRoot}
    >
      {!hideBack && <BackButton />}
      <Typography
        id={id}
        className={classes.contentTitle}
        variant="h5-medium"
        color='primary.main'
        textTransform='capitalize'
      >
        {title}
      </Typography>
      <Box className={classes.contentActions}>
        {children}
      </Box>
    </ShadowBox>
  );
}

export default ScreenHeading;
import Box from "@mui/material/Box";
import React from "react";

import { useSnackbar } from "contexts";
import { Button, Card, CardContent, Collapse, Stack, TextField, Typography } from "@mui/material";
import { Session, formatDate } from "utils";
import { getUsageOverview, getUserBalanceInfo, getUserCredits, getUserTransactions } from "services";
import StaticTable from "components/Tables/StaticTable";
import { TransactionType } from "./constants";
import dayjs from "dayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarIcon from '@mui/icons-material/CalendarToday';
import { SubscriptionModel } from "editors/Batch/constants";
import InfoIconWithTooltip from "components/InfoIconWithTooltip";
import Pagination from "components/Pagination";

const sx = {
  transactionButton : { 
    color: '#000', 
    fontSize: '18px', 
    fontWeight: '500', 
    textTransform: 'capitalize', 
    padding: '0px',
    marginBottom: '5px'
  },
  overviewBox: {
    boxShadow: '4px -4px 24px 0px rgba(0, 0, 0, 0.25) inset',
    display: 'flex',
    justifyContent: 'space-evenly',
    border: '2px solid #D3D8DE',
    padding: '10px',
    width: '100%',
    borderRadius: '4px',
    marginTop: '8px'
  },
  head: {
    fontFamily:'Inter',
    fontSize: '16px',
    fontWeight: 600,
    marginBottom: '10px'
  },
  card: {
    width: '22%',
    minHeight: '70px',
    height: '70px',
    display:'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F4F0FF'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px 5px'
  },
  filterBtn: { 
    textTransform: 'capitalize', padding: '0px',
    minWidth: '0px', color: '#000', borderRadius: '0px',
  },
  filterText: {
    fontSize: '14px', fontWeight: 500
  }
}

const payPerAttemptUserColumns = [
  {
    field: "createdAt", label: "Added On", 
    renderCell: ({row}) => formatDate(row.createdAt,'DD-MM-YYYY')
  },
  {
    field: "description", label: "Description", align: "center",
  },
  {
    field: "initialAmount", label: "Quantity Added", align: "center"
  },
  {
    field: "usedAmount", label: "Quantity Used", align: "center"
  },
  {
    field: "expiresOn",  label: "Expiry", align: "center",
    renderCell: ({row}) => formatDate(row.expiresOn,'DD-MM-YYYY')
  },
]

const creditColumns = [
  {
    field: "createdAt", label: "Added On", 
    renderCell: ({row}) => formatDate(row.createdAt,'DD-MM-YYYY')
  },
  {
    field: "description", label: "Description", align: "center",
  },
  {
    field: "initialAmount", label: "Quantity Added", align: "center"
  },
  {
    field: "remainingAmount", label: "Quantity Remaining", align: "center"
  },
  {
    field: "expiresOn",  label: "Expiry", align: "center",
    renderCell: ({row}) =>{
      if(dayjs(row.expiresOn).isBefore(dayjs())){
        return <Typography color='red'>Expired</Typography>
      }
      else{
        return formatDate(row.expiresOn,'DD-MM-YYYY')
      }
    } 
  },
]

const transactionColumns = [
  {
    field: "createdAt", label: "Date",
    renderCell: ({row}) => formatDate(row.createdAt,'DD-MM-YYYY')
  },
  {
    field: "description", label: "Description", align: "center",
    minWidth: 70, maxWidth: 100,
    renderCell: ({row}) =>{
      if( row.type === TransactionType.CREDIT){
        return 'Licences Added'
      }
      if( row.type === TransactionType.DEBIT){
        return 'Access Given'
      }
      if( row.type === TransactionType.REFUND){
        return 'Access Removed'
      }
      else{ return '-' }
    }
  },
  {
    field: "type", label: "Type", align: "center"
  },
  {
    field: "amount", label: "amount", align: "center",
    renderCell: ({row}) => {
      if(row.type === TransactionType.DEBIT){
        return (
          <span style={{color: "red"}}>- {row.amount}</span>
          )
      }
      else{
        return (
          <span style={{color: "green"}}>+ {row.amount}</span>
          )
      }
    }
  },
  {
    field: "status", label: "status", align: "center"
  },
]

const Summary = ({title, value, toolTip=null}) => {
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
      <Box display='flex' alignItems='center' gap='5px'>
        <Typography variant="body01-medium">
          {title}
        </Typography>
        {
          toolTip ?
          <InfoIconWithTooltip
              placement='right'
              title={toolTip}
              style={{ fontSize: '14px'}}
          />
          : null
        }
      </Box>
      <Typography display='flex' variant="body01-bold">
        {value || 0}
      </Typography>
    </Box>
  )
}

export const UsageCard = ({ title, data, toolTip }) => {
  return (
    <Card sx={sx.card}>
      <CardContent  style={sx.cardContent}>
        <Box display='flex' alignItems='center'>
          <Typography variant="body01-medium">
            {title}
          </Typography>
          <InfoIconWithTooltip
              placement='right'
              title={toolTip}
              style={{ fontSize: '14px' , marginLeft : '4px'}}
          />
        </Box>
        <Typography variant="body01-bold">
          {data}
        </Typography>
      </CardContent>
    </Card>
  );
};

export const DatePicker = ({ name, title, date, handleDateChange }) => {

  const [openDatePicker, setOpenDatePicker] = React.useState(false);

  return (
    <Box>
      <Button
          style={{ borderRadius: 5, marginLeft: 0 }}
          size="small"
          startIcon={<CalendarIcon style={{fontSize: "15px"}}/>}
          variant={date ? "contained" : "outlined"}
          onClick={() => setOpenDatePicker(true)}
      >
          {date ? (
            <Typography variant="body02-semiBold" textTransform='capitalize'>
              {dayjs(date).format("DD MMM YYYY")}
            </Typography>
          ) : (
            <Typography variant="body02-semiBold" textTransform='capitalize'>
              {title}
            </Typography>
          )}
      </Button>
      <div
        style={{
            visibility: 'hidden',
            overflow: "hidden",
            height: 0,
            width: 0,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker 
            open={openDatePicker}
            onClose={() => setOpenDatePicker(false)}
            value={date || null}
            onChange={(value)=> handleDateChange(name,value)}
            renderInput={ (params) => 
              <TextField 
                {...params} 
              />
            }
          />
        </LocalizationProvider>
      </div>
    </Box>
  )
}



export default function Billing() {
    const snackbar = useSnackbar();
    const user = Session.user;

    const [userBalanceInfo, setUserBalanceInfo] = React.useState({});
    const [usageOverview, setUsageOverview] = React.useState({});
    const [credits, setCredits] = React.useState([]);
    const [transactions, setTransactions] = React.useState([]);
    const [pageNo, setPageNo] = React.useState(1);
    const [totalTransaction, setTotalTransaction] = React.useState(0);
    const [dateRange, setDateRange] = React.useState(null);
    const [filter, setFilter] = React.useState(null);
    const isPayPerUser = (user?.metadata?.subscriptionModel === SubscriptionModel.PAY_PER_USER);

    const handleDateChange = (name, newDate) => {
      setDateRange({...dateRange, [name]: dayjs(newDate).toISOString()})
    }

    const getBalanceInfo = async () => {
      try {
        const balanceInfo = await getUserBalanceInfo(Session.userId);
        setUserBalanceInfo(balanceInfo)
      } catch (error) {
        snackbar.showSnackbar(error.message, "error");
      }
    };

    const getCredits = async () => {
      try {
        const creditsResult = 
          await getUserCredits(Session.userId, { orderBy: "createdAt", order: "desc" });
        setCredits(creditsResult.credits);
      } catch (error) {
        snackbar.showSnackbar(error.message, "error");
      }
    };

    React.useEffect(() => {
      (async () => {
        try {
          let txnFilters = { 
            orderBy: "createdAt", order: "desc",includeTotal: 1,
            pageSize: 10, pageNumber: pageNo
          }
          
          if(filter) txnFilters.type = filter;

          const txnResult = 
            await getUserTransactions(Session.userId, txnFilters) ;
          setTransactions(txnResult.transactions);
          setTotalTransaction(txnResult.total)
        } catch (error) {
          snackbar.showSnackbar(error.message, "error");
        }
      })();
    },[pageNo, filter]);

    React.useEffect(() => {
      (async () => {
        try {
          const usage = 
            await getUsageOverview(Session.userId, dateRange || {});
          setUsageOverview(usage)
          setUsageOverview(usage);
        } catch (error) {
          snackbar.showSnackbar(error.message, "error");
        }
      })();
    },[dateRange]);

    React.useEffect(() => {
      getBalanceInfo();
      getCredits();
    },[]);

    return (
        <Box>
          <Box border='2px solid #D3D8DE' padding='15px' mt='10px' borderRadius='4px'>
            <Box display='flex' justifyContent='space-between'>
              <Typography variant="h6-medium">Usage Overview</Typography>
              <Box>
                <Box display='flex' gap='10px'>
                  <DatePicker
                    name='startDate'
                    title='Pick Start Date'
                    date={dateRange?.startDate || null}
                    handleDateChange={handleDateChange}
                  />
                  <DatePicker
                    name='endDate'
                    title='Pick End Date'
                    date={dateRange?.endDate || null}
                    handleDateChange={handleDateChange}
                  />
                </Box>
                {
                  dateRange &&
                  <Box display='flex' justifyContent='flex-end'>
                    <Button 
                      onClick={()=>setDateRange(null)}
                    >
                      <Typography variant="body01-link"> Reset</Typography>                  
                    </Button>
                  </Box>
                }
              </Box>
            </Box>
            <Box display='flex' width='100%' justifyContent='space-between' mt='20px' mb={2}>
              <UsageCard 
                title={'Students Onboarded'} data={usageOverview?.onboardedUsers || 0}
                toolTip={'Indicates count of students who have joined the platform'}
              />
              <UsageCard 
                title={'No. of Attempts'} data={usageOverview?.totalAttempts || 0}
                toolTip={'Overall count of attempts made by students'}
              />
              <UsageCard 
                title={'Batches Created'} data={usageOverview?.totalBatches || 0}
                toolTip={'Total number of batches created within the platform'}
              />
              <UsageCard 
                title={'Assessments Published'} data={usageOverview?.totalTemplates || 0}
                toolTip={'Total count of assessments published for students'}
              />
            </Box>
            <Typography variant="body02-semiBold">
              <span style={{ color: 'red'}}>*</span>Use the date range filter to customize the time period for which you want to analyze the data.
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column' mt='15px'>
            <Typography variant="h6-medium">
              Licences Overview:
            </Typography>
            <Box sx={sx.overviewBox}>
              <Summary 
                title={"Licences Remaining"} 
                value={userBalanceInfo?.remainingAmount || 0}
                toolTip={
                  isPayPerUser ? 'Remaining capacity for onboarding new users'
                  : 'Remaining capacity for new attempts'
                  }
              />
              <Summary title={"Licences Used"} value={userBalanceInfo?.usedAmount || 0}
                toolTip={
                  isPayPerUser ? 'Total number of licenses utilized for onboarding users'
                  : 'Total number of licenses utilized for attempts'
                }
              />
              <Summary title={"Active Credits"} value={userBalanceInfo?.activeCredits || 0} 
                toolTip={'The number of credits currently in use.'}
              />
              <Summary title={"Subscription Mode"} value={isPayPerUser ? 'Pay Per User' : 'Pay Per Attempt'} 
                toolTip={'Shows your chosen billing method'}
              />
            </Box>
          </Box>
          <Box mt='30px'>
            <Box mb={2}>
              <Typography variant="h6-medium">
                Credits:
              </Typography>
            </Box>
            <StaticTable
              height='300px'
              noRowsMessage="No Licences Found!!"
              rows={credits}
              columns={isPayPerUser? creditColumns : payPerAttemptUserColumns}
              showRowSelectCheckbox={false}
              showColumnSelection={false}
              showExports={false}
            />
          </Box>
          {
            isPayPerUser ?
            <Box mt='30px'>
              <Box display='flex' justifyContent='space-between'>
                <Typography mb='10px' style={sx.transactionButton}>
                  Transactions:
                </Typography>
                <Box display='flex' gap='15px'>
                  <Button onClick={()=>setFilter(null)} style={sx.filterBtn}>
                    <Typography sx={sx.filterText}
                      borderBottom={filter === null ? '2px solid #02569D' : 'none'}
                    >
                      All
                    </Typography>
                  </Button>
                  {
                    Object.keys(TransactionType).map(key=>{
                      return(
                        <Button key={key} onClick={() => setFilter(TransactionType[key])} 
                          style={sx.filterBtn}
                        >
                          <Typography sx={sx.filterText} 
                            borderBottom={filter === TransactionType[key] ? '2px solid #02569D' : 'none'}
                          >
                            {TransactionType[key]}
                          </Typography>
                        </Button>
                      )
                    })
                  }
                </Box>
              </Box>
              <StaticTable
                noRowsMessage="No Transactions Found!!"
                rows={transactions}
                columns={transactionColumns}
                showRowSelectCheckbox={false}
                showColumnSelection={false}
                showExports={false}
              />
              <Box display='flex' justifyContent='flex-end' mt='10px'>
                <Stack spacing={2}>
                  <Pagination 
                    count={Math.ceil(totalTransaction/10)}
                    setPage={setPageNo}
                    page={pageNo}
                    variant="outlined" 
                    shape="rounded" 
                  />
                </Stack>
              </Box>
            </Box>
            : null
          }
        </Box>
    );
}
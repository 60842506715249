import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import AssessmentIcon from '@mui/icons-material/AssessmentOutlined';
import PersonIcon from '@mui/icons-material/PersonOutlineOutlined';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from '@mui/material';
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from '@mui/material/styles';

import ActionButtons from 'components/ActionButtons';
import ScreenHeading from "components/ScreenHeading";
import { useNavigatingAway } from 'hooks';
import { BeforeLeave } from 'dialogs';
import StudentList from './StudentList';
import TemplateList from './TemplateList';
import { Session, copyToClipboard } from 'utils';
import { useLoading, useSnackbar } from 'contexts';
import { getBatchById, saveBatch } from 'services';
import TextField from 'components/CustomFields';
import { WhiteBox } from 'components/AppShell';

const useStyles = makeStyles(theme=>({
  root: {
    display: "flex", flexDirection: "column",
    minHeight: 'calc(100vh - 76px)',
    paddingBottom: theme.spacing(6)
  },
  batchContainer:{
    display:'flex',
    justifyContent:'space-between'
  },
  batchEditContainer: {
    display: "flex", alignItems :'flex-end',
    gap: '10px',
  },
  batchInfo: {
    display: "flex", 
    gap: theme.spacing(1), 
    alignItems: "center"
  },
  batchIdBox: {
    display:'flex',
    marginTop: theme.spacing(1), alignItems: 'center',
    border: '1px solid',
    borderColor: theme.palette.neutral['clr-200'],
    width: 'fit-content',
    padding: theme.spacing(1,3)
  }
}));

function Batch(props) {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate()
  const { id } = useParams();
  const snackbar = useSnackbar();
  const loading = useLoading();
  const [batch, setBatch] = React.useState({});
  const [activeStudents, setActiveStudents] = React.useState(0);
  const [activeAssessment, setActiveAssessment] = React.useState(0);
  const [canShowBeforeLeave, setCanShowBeforeLeave] = React.useState(false);
  const [ isButtonDisable , setIsButtonDisable] = React.useState(false)
  const [
    showDialogLeavingPage, confirmNavigation, cancelNavigation
  ] = useNavigatingAway(canShowBeforeLeave);

  React.useEffect(() => {
    if (id && id !== "create") {
      getBatchById(id).then((batch) => {
        setBatch(batch)
        setIsButtonDisable(true)
      })
        .catch(error => snackbar.showSnackbar(error.message, "error"))
        .then(console.error)
    }
  }, []);

  const handleSave = async () => {
    try {
      setCanShowBeforeLeave(false);
      loading.show();

      const savedBatch = await saveBatch(id !== "create" && id, {
        name: batch.name,
        client: Session.getUser()._id,
      });

      setBatch(savedBatch);
      navigate(`/batches/${savedBatch._id}`);

      snackbar.showSnackbar("Saved batch!!");
    } catch (error) {
      snackbar.showSnackbar(error.message, "error");
    } finally {
      loading.hide();
    }
  };

  const handleDiscard = async () => {
    setCanShowBeforeLeave(false);
    navigate(-1);
  };

  const onStudentCountChange = (newCount) => {
    setBatch(b => ({ ...b, studentCount: newCount }));
  };

  const onTemplateCountChange = (newCount) => {
    setBatch(b => ({ ...b, templateCount: newCount }));
  };

  return (
    <Box className={classes.root}>
      <BeforeLeave
        showDialog={showDialogLeavingPage}
        setShowDialog={setCanShowBeforeLeave}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <ScreenHeading title={id === "create" ? "Create Batch" : "Batch Editor"} />

    <Box display='flex' flexDirection='column' gap='12px'>
    <WhiteBox >
      <Box className={classes.batchContainer}>
        <Box className={classes.batchEditContainer}>
            <TextField
              label='Batch name'
              placeholder="E.g. Batch: 01"
              style={{ maxWidth: 300, marginRight: 16 }}
              value={batch?.name || ''}
              onChange={e => setBatch(b => { 
                const updatedBatch = { ...b, name: e.target.value };
                setIsButtonDisable(false);
                return updatedBatch;
            })}          
            />
            <ActionButtons
              primaryFirst
              onPrimaryClick={handleSave}
              onSecondaryClick={handleDiscard}
              primaryAction="Save"
              secondaryAction="Discard"
              disabled={isButtonDisable}
            />
        </Box>
        <Box display="flex" flexDirection='column' justifyContent='space-between'>
            {id !== "create" && (
                  <Box className={classes.batchInfo}>
                    <PersonIcon fontSize="small" style={{ color: theme.palette.neutral['clr-900'] }} />
                    <Typography variant='body01-bold'>
                      {activeStudents} Active Students
                    </Typography>
                    <Divider variant="middle" orientation='vertical' flexItem sx={{ my: '2px' }} />
                    <AssessmentIcon fontSize="small" style={{ color: theme.palette.neutral['clr-900'] }} />
                    <Typography variant='body01-bold'>
                      {activeAssessment} Assessments
                    </Typography>
                  </Box>
                )}
                {
                  batch?._id &&
                    <Box display='flex' justifyContent='flex-end' alignItems='flex-end'>
                    <Box className={classes.batchIdBox}>
                      <Typography variant='body01-bold'>
                        Batch Id:&nbsp;
                      </Typography>

                      <Typography variant='body01-bold' color='neutral.clr-600'>
                        {batch?._id}
                      </Typography>
                      <IconButton style={{ marginLeft: '4px', padding: 0}} onClick={() => {
                        copyToClipboard(batch?._id);
                        snackbar.success('Copied!');
                      }}>
                        <ContentCopyIcon style={{ fontSize: '20px', color: theme.palette.primary['clr-300']}}/>
                      </IconButton>
                    </Box>
                    </Box>
                }
          </Box>
         </Box>
        </WhiteBox>
        {/* <Collapse in={id !== "create" && !!batch?.joiningLink}>
          {InviteLink({ id, joiningLink: batch?.joiningLink })}
        </Collapse> */}
        <WhiteBox>
          <Collapse in={id !== "create"}>
            {StudentList({ onStudentCountChange, setActiveStudents })}
          </Collapse>
        </WhiteBox>
       <WhiteBox>
        <Collapse in={id !== "create"}>
            {TemplateList({ onTemplateCountChange, setActiveAssessment })}
          </Collapse>
       </WhiteBox>
        <br />
    </Box>
      
    </Box>
  );
}

export default Batch;